import { plainToClass } from 'class-transformer';
import {
  doSecureGetRequest
} from './ApiService';
import Workflow from '../model/Workflow';

function fetchWorkflow(workflowName: string): Promise<Workflow> {
  return doSecureGetRequest<Workflow>(`/workflow/${workflowName}`)
    .then((w) => plainToClass(Workflow, w));
}

export default {
  fetchWorkflow
};
