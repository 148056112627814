import { RouteConfig } from 'vue-router';
import VueRouter from '@improve/common-utils/src/router';
import FEATURE from '@improve/common-utils/src/model/Feature';
import store from '../store';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      feature: FEATURE.IMPROVE_ADMIN
    }
  },
  {
    path: '/users',
    name: 'Users',
    children: [
      {
        path: 'all-users',
        name: 'AllUsers',
        meta: { // TODO: remove me
          feature: FEATURE.IMPROVE_ADMIN_OTHER,
          selectedNavigationItem: 'Users'
        },
        component: () => import(/* webpackChunkName: "admin-users-all-users" */ '@improve/common-components/src/components/admin/users/GenericUserList.vue')
      },
      {
        path: 'admins',
        name: 'Admins',
        meta: { // TODO: remove me
          feature: FEATURE.IMPROVE_ADMIN_OTHER,
          selectedNavigationItem: 'Users'
        },
        component: () => import(/* webpackChunkName: "admin-users-admins" */ '@improve/common-components/src/components/admin/users/GenericUserList.vue')
      }
    ],
    redirect: '/users/all-users'
  },
  {
    path: '/teams',
    name: 'Teams'
  },
  {
    path: '/teams/add/:type',
    name: 'CreateTeam',
    meta: {
      selectedNavigationItem: 'Teams'
    }
  },
  {
    path: '/teams/edit/:id',
    name: 'EditTeam',
    meta: {
      selectedNavigationItem: 'Teams'
    }
  },
  {
    path: '/topics',
    name: 'Topics'
  },
  {
    path: '/topics/add',
    name: 'CreateTopic',
    meta: {
      selectedNavigationItem: 'Topics'
    }
  },
  {
    path: '/topics/edit/:id',
    name: 'EditTopic',
    meta: {
      selectedNavigationItem: 'Topics'
    }
  },
  {
    path: '/improves',
    name: 'Improves'
  },
  {
    path: '/notifications',
    name: 'Notifications'
  },
  {
    path: '/assessments',
    name: 'Assessments',
    meta: {
      feature: FEATURE.IMPROVE_ADMIN_ASSESSMENT
    }
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      publicAccess: true
    }
  }
]
  .map((route: RouteConfig) => ({
    component: () => import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`),
    // TODO: Just to make it easy to disable everything,
    //  remove this meta block when we have actual feature
    meta: {
      feature: FEATURE.IMPROVE_ADMIN_OTHER
    },
    ...route
  }));

export default VueRouter(routes, store, true);
