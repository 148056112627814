import FEATURE from '../model/Feature';

// TODO: remove when we have the api
const fetchedFeatures = process.env.VUE_APP_FEATURES
  ?.split(',')
  .filter((feature) => !!feature) as FEATURE[];

async function fetchFeatures(): Promise<FEATURE[]> {
  return fetchedFeatures?.length
    ? fetchedFeatures
    // Fall back to features if VUE_APP_FEATURES isn't set
    : Object.values(FEATURE);
}

export default { fetchFeatures };
