// eslint-disable-next-line max-classes-per-file
import { Type } from 'class-transformer';
import CommonDto from './CommonDto';

export enum DynamicDSTypes {
  STATIC = 'STATIC',
  INTERNAL_API = 'INTERNAL_API',
  EXTERNAL_API = 'EXTERNAL_API'
}

export class DynamicDataSource {
  type!: DynamicDSTypes;

  @Type(() => Map)
  settings!: Map<string, string>;
}

const parseDataSource = (datasource: DynamicDataSource): any => {
  if (!datasource) return null;
  try {
    const valuesStr = datasource.settings.get('value') || '[]';
    return JSON.parse(valuesStr);
  } catch (ex) {
    return datasource;
  }
};

export class DynamicColumn {
  // Supported names (form control names) in FE: text/textarea/select/table
  name!: string;

  key!: string;

  label!: string;

  format: string | null = null;

  defaultValue: string | null = null;

  @Type(() => DynamicDataSource)
  datasource: DynamicDataSource | null = null;

  getDataSource(): any {
    return parseDataSource(this.datasource!);
  }
}

export class FieldOptions {
  @Type(() => DynamicDataSource)
  datasource: DynamicDataSource | null = null;

  @Type(() => DynamicColumn)
  columns: Array<DynamicColumn> = [];

  getDataSource(): any {
    return parseDataSource(this.datasource!);
  }
}

export class ExternalField {
  ref!: string;

  // Supported types in FE: text/textarea/select/table/number
  type!: string;

  label: string | null = null;

  placeHolder: string | null = null;

  required = false;

  @Type(() => FieldOptions)
  options: FieldOptions | null = null;
}

export class FieldGroup {
  groupId!: string;

  groupLabel: string | null = null;

  @Type(() => ExternalField)
  fields: Array<ExternalField> = [];
}

export default class DynamicField extends CommonDto {
  id: string | null = null;

  context!: string;

  type!: string;

  @Type(() => FieldGroup)
  externalFields: Array<FieldGroup> = [];
}
