import { ActionTree } from 'vuex';
import getAssessmentUrl from '@improve/common-utils/src/services/LeanAssessmentService';

const actions: ActionTree<any, any> = {
  fetchLeanAssessmentUrl(): string | null {
    return getAssessmentUrl();
  }
};

export default {
  actions
};
