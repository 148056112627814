import Transition from './Transition';
import NotificationType from './NotificationType';

export interface StatusSetting {
  settings: {
    showOnAdminBoard: boolean;
  }
}

export interface WorkflowSettings {
  status: {
    [key: string]: StatusSetting
  }
}

export default class Workflow {
  name: string;

  transitions: Array<Transition>;

  settings: WorkflowSettings;

  notifications: Array<NotificationType>;

  constructor(
    name: string,
    transitions: Array<Transition>,
    settings: WorkflowSettings,
    notifications: Array<NotificationType>
  ) {
    this.name = name;
    this.transitions = transitions;
    this.settings = settings;
    this.notifications = notifications;
  }
}
