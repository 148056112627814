import * as Throttle from 'promise-parallel-throttle';
import { Base64Response, SingleFileResponse } from '../types/FileUploadResponse';
import FileUploadRequest from '../types/FileUploadRequest';
import {
  GetSignedUrlRequest, StartMultiUploadRequest, StopMultiUploadRequest
} from '../types/MultiUploadRequest';
import {
  GetSignedUrlResponse, StartMultiUploadResponse, StopMultiUploadResponse
} from '../types/MultiUploadResponse';
import { doSecureDeleteRequest, doSecureFileUpload, doSecurePostRequest } from './ApiService';
import { VUFile } from '../types/VUFile';
import { UploadState } from '../types/UploadState';

// Max part number is 10,000
// https://docs.aws.amazon.com/AmazonS3/latest/API/API_UploadPart.html#API_UploadPart_RequestSyntax
const bufferSize = 5 * 1024 * 1024;

// The minimum size for segments is 5 Mb
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html

function uploadBase64File(
  payload: FileUploadRequest
): Promise<Base64Response> {
  return doSecurePostRequest<Base64Response>('/fileUpload/base64', payload);
}

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  const [, type] = dataUrl.split(';')[0].split(':');
  return new File([blob], fileName, { type });
}

function convertVideo(canonicalId: string, fieldName: string): void {
  doSecurePostRequest<void>(`/video/${canonicalId}/${fieldName}/convert`);
}

function startMultiUpload(
  ticketId: string,
  payload: StartMultiUploadRequest
): Promise<StartMultiUploadResponse> {
  return doSecurePostRequest<StartMultiUploadResponse>(`/ticket/fileUpload/${ticketId}/start`, payload);
}

function getSignedUrl(
  payload: GetSignedUrlRequest
): Promise<GetSignedUrlResponse> {
  return doSecurePostRequest<GetSignedUrlResponse>('/fileUpload/multiUpload/getSignedUrl', payload);
}

function stopMultiUpload(
  ticketId: string,
  payload: StopMultiUploadRequest
): Promise<StopMultiUploadResponse> {
  return doSecurePostRequest<StopMultiUploadResponse>(`/ticket/fileUpload/${ticketId}/stop`, payload);
}

async function processMultiUpload(
  inputId: string,
  uploadId: string,
  file: VUFile,
  key: string,
  callBack: (up: UploadState) => void
): Promise<string[]> {
  const segments: number = Math.ceil(file.size / bufferSize);
  const tasks: boolean[] = Array(segments).fill(false);
  const contentType: string = file.type;

  const parts: string[] = await Throttle.all(tasks.map(Function.call,
    (i: number) => async (): Promise<string> => {
      const url = await getSignedUrl({
        key,
        uploadId,
        contentType,
        partNumber: i + 1
      });
      const start = i * bufferSize;
      const end = start + bufferSize;
      const response: Response = await fetch(url, {
        method: 'PUT',
        body: file.file.slice(start, end > file.size ? file.size : end)
      });
      tasks[i] = true;
      return response.headers.get('etag') || '';
    }) as Throttle.Task<string>[],
  {
    maxInProgress: 2,
    progressCallback(progress) {
      if (!uploadId) {
        return;
      }
      callBack({
        inputId,
        uploadProgress: (100 * progress.amountDone) / segments
      });
    }
  });
  return parts;
}

function removeAttachment(
  ticketCanonicalId: string,
  fileId: string
): Promise<void> {
  return doSecureDeleteRequest<void>(`/ticket/fileUpload/${ticketCanonicalId}/${fileId}`);
}

function uploadFile(
  fileName: string,
  payload: File
): Promise<SingleFileResponse> {
  const formData = new FormData();
  formData.append('file', payload);
  formData.append('tags', '');
  return doSecureFileUpload<SingleFileResponse>('/fileUpload', formData);
}

export {
  convertVideo,
  dataUrlToFile,
  uploadBase64File,
  uploadFile,
  processMultiUpload,
  startMultiUpload,
  stopMultiUpload,
  removeAttachment,
  getSignedUrl
};
