import { Type } from 'class-transformer';
import TicketStats from '../types/TicketStats';

export default class StatsData {
  numberOfOwners: number;

  numberOfMembers: number;

  numberOfExperts: number;

  @Type(() => TicketStats)
  ticketStats: TicketStats;

  constructor() {
    this.numberOfMembers = 0;
    this.numberOfOwners = 0;
    this.numberOfExperts = 0;
    this.ticketStats = new TicketStats();
  }

  getNumberOfTickets(): number {
    return this.ticketStats.totalNonArchived;
  }

  getNumberOfTeamMembers(): number {
    return this.numberOfMembers + this.numberOfOwners;
  }
}
