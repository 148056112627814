import { Route, NavigationGuardNext } from 'vue-router';
import { Store } from 'vuex';

const featureGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  store: Store<any> // Vuex.Store
): Promise<void> => {
  if (store.getters.isAuthenticated
    && !store.getters.features?.length) {
    await store.dispatch('fetchFeatures');
  }

  if (to.meta?.publicAccess
    || !store.getters.isAuthenticated
    || !to.meta?.feature
    || store.getters.isFeatureEnabled(to.meta.feature)
  ) {
    next();
  } else {
    store.dispatch('logout');
  }
};

export default featureGuard;
