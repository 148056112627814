import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { SecurityState } from '../../types/SecurityState';
import { getCurrentAuth, userIsAdmin } from '../../services/SecurityService';
import RoleType from '../../types/RoleType';
import UserAuthObject from '../../model/UserAuthObject';

const state: SecurityState = {};

const getters: GetterTree<SecurityState, any> = {
  userIsSuperAdmin: () => {
    const user: UserAuthObject | null = getCurrentAuth();
    return user?.roles.includes(RoleType.SUPER_ADMIN);
  },
  userIsAdmin: () => () => {
    const user: UserAuthObject | null = getCurrentAuth();
    return userIsAdmin(user!);
  },
  userIsBasicUserOnly: () => {
    const user: UserAuthObject | null = getCurrentAuth();
    return user?.roles.includes(RoleType.USER);
  },
  userIsExpertUserOnly: () => {
    const user: UserAuthObject | null = getCurrentAuth();
    return user?.roles.includes(RoleType.EXPERT);
  },
  currentAuth: () => getCurrentAuth()
};

const mutations: MutationTree<SecurityState> = {};

const actions: ActionTree<SecurityState, any> = {};

export default {
  state,
  getters,
  mutations,
  actions
};
