import { plainToClass } from 'class-transformer';
import Team from '../model/Team';

import {
  doSecurePostRequest, doSecureGetRequest,
  doSecureDeleteRequest, doSecurePutRequest
} from './ApiService';
import StatsData from '../model/StatsData';

function createTeam(newTeam: Team): Promise<Team> {
  return doSecurePostRequest<Team>('/team', newTeam)
    .then((team) => plainToClass(Team, team));
}

function updateTeam(
  teamUpdate: Team
): Promise<Team> {
  return doSecurePutRequest<Team>(`/team/${teamUpdate.id}`, teamUpdate)
    .then((team) => plainToClass(Team, team));
}

function removeTeam(
  teamRemove: Team
): Promise<string> {
  return doSecureDeleteRequest<string>(`/team/${teamRemove.id}`)
    .then((response) => response);
}

function addTeamMember(teamId: string, memberId: string, type: string): Promise<string> {
  return doSecurePostRequest<string>(`/team/${teamId}/member/${memberId}/${type}`)
    .then((response) => response);
}

function removeTeamMember(teamId: string, memberId: string): Promise<string> {
  return doSecureDeleteRequest<string>(`/team/${teamId}/member/${memberId}`)
    .then((response) => response);
}

function fetchTeamMembers(teamId: string): Promise<Map<string, number>> {
  return doSecureGetRequest<any>(`/team/${teamId}/members`)
    .then((teamMembers) => (
      new Map(Object.keys(teamMembers).map((uId: string) => [uId, parseInt(teamMembers[uId], 10)]))
    ));
}

function fetchTeamStats(
  targets: Array<string>,
  cached = true
): Promise<Map<string, StatsData>> {
  return doSecurePostRequest<any>(
    `/stats/fetchTeamStatsByBatch?cached=${cached}`,
    { targets }
  ).then((teamStats) => {
    const allTeamStats: Map<string, StatsData> = new Map<string, StatsData>();
    Object.keys(teamStats).forEach((userId: string) => {
      allTeamStats.set(userId, plainToClass(StatsData, teamStats[userId]));
    });
    return allTeamStats;
  });
}

export default {
  createTeam,
  updateTeam,
  removeTeam,
  addTeamMember,
  removeTeamMember,
  fetchTeamMembers,
  fetchTeamStats
};
