export default class TicketMetaInfo {
  problem: string | undefined;

  solution: string | undefined;

  link: string | undefined;

  description: string | undefined;

  tagline: string | undefined;

  tags: string | undefined;

  startDate: string | undefined;

  implementationDate: string | undefined;

  jiraUrl: string | undefined;

  wontDoReason: string | undefined;

  constructor() {
    this.startDate = new Date().toISOString().substr(0, 7);
  }
}
