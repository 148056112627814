export default class ReactionEvent {
  constructor(author: string, createdAt: Date, counter: number) {
    this.author = author;
    this.createdAt = createdAt;
    this.counter = counter;
  }

  author: string;

  createdAt: Date;

  counter: number;
}
