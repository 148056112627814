import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { DynamicFieldState } from '../../types/DynamicFieldState';
import DynamicField, { FieldGroup } from '../../model/DynamicField';
import DynamicFieldsService from '../../services/DynamicFieldsService';

const state: DynamicFieldState = {
  externalFields: null
};

const getters: GetterTree<DynamicFieldState, any> = {
  externalFields: (st) => st.externalFields
};

const mutations: MutationTree<DynamicFieldState> = {
  saveExternalFields(st: DynamicFieldState, payload: DynamicField) {
    st.externalFields = payload.externalFields;
  }
};

const actions: ActionTree<DynamicFieldState, any> = {
  async fetchExternalFields({ commit }, type: string): Promise<Array<FieldGroup>> {
    const dynamicField = await DynamicFieldsService.fetchDynamicField(type);
    commit('saveExternalFields', dynamicField);
    return Promise.resolve(dynamicField.externalFields);
  },
  async fetchDynamicData(
    { commit },
    { url, searchFor } : { url: string; searchFor: string }
  ): Promise<any> {
    return DynamicFieldsService.fetchDynamicData(url, searchFor);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
