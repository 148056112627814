import _Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import translations from '../locales/en.json';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', translations.dayjs);

export default (Vue: typeof _Vue): void => {
  Object.defineProperties(Vue.prototype, {
    $date: {
      get() {
        return dayjs;
      }
    }
  });
};
