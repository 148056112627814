import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { plainToClass } from 'class-transformer';

import OrganizationService from '../../services/OrganizationService';
import Organization from '../../model/Organization';
import { OrganizationState } from '../../types/OrganizationState';
import SSO_MODE from '../../model/SsoMode';

const state: OrganizationState = {
  currentOrganization: null
};

const getters: GetterTree<OrganizationState, any> = {
  currentOrganization: (st) => st.currentOrganization
};

const mutations: MutationTree<OrganizationState> = {
  setCurrentOrganization(st: OrganizationState, payload: Organization) {
    st.currentOrganization = plainToClass(Organization, payload);
  }
};

const actions: ActionTree<OrganizationState, any> = {
  fetchOrgAnonymously(context, email: string): Promise<Organization> {
    const domain = email.substring(email.indexOf('@') + 1, email.length);
    return OrganizationService.fetchMinimalOrgByDomain(domain);
  },

  async fetchCurrentOrganization({ commit, dispatch }): Promise<Organization> {
    const org = await OrganizationService.fetchCurrentOrganization();
    commit('setCurrentOrganization', org);
    dispatch('setOrganizationCustomization', org);
    await dispatch('setUnitsAndTeams', org, { root: true });
    return Promise.resolve(org);
  },

  fetchOrgSsoMode(context, email: string): Promise<SSO_MODE> {
    const domain: string = email.split('@')[1].toLowerCase();
    return OrganizationService.fetchSsoModeOrganization(domain);
  },

  async fetchOrgSsoLogin(context, email: string): Promise<string> {
    const domain: string = email.split('@')[1].toLowerCase();
    return OrganizationService.fetchSsoLoginOrganization(domain);
  }

};

export default {
  state,
  getters,
  mutations,
  actions
};
