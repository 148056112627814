import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { classToClass, plainToClass } from 'class-transformer';
import { TeamState } from '../../types/TeamState';
import { TeamMemberAction } from '../../types/TeamMemberAction';
import GaleraRoles from '../../types/GaleraRoles';
import Team, { MEMBER_TYPE, TEAM_TYPE } from '../../model/Team';
import StatsData from '../../model/StatsData';
import Organization from '../../model/Organization';
import TeamService from '../../services/TeamService';

const state: TeamState = {
  organizationalUnits: [],
  organizationalUnitsByID: new Map<string, Team>(),
  teams: [],
  teamsByID: new Map<string, Team>(),
  teamMembersByTeamId: new Map<string, Map<string, number>>(),
  teamStatsByID: new Map<string, StatsData>()
};

const updateTeamsByID = () => {
  state.teamsByID = new Map<string, Team>(state.teams.map((t) => [t.id!, t]));
};

const updateOrgByID = () => {
  state.organizationalUnitsByID = new Map<string, Team>(
    state.organizationalUnits.map((t) => [t.id!, t])
  );
};

const updateTeamMembersByID = () => {
  state.teamMembersByTeamId = classToClass(state.teamMembersByTeamId);
};

const getters: GetterTree<TeamState, any> = {
  organizationalUnits: (st) => st.organizationalUnits,
  organizationalUnitsByID: (st) => st.organizationalUnitsByID,

  teams: (st) => st.teams,
  teamsByID: (st) => st.teamsByID,

  allTeamsById: (st) => (id: string) => (
    st.teamsByID.get(id) || st.organizationalUnitsByID.get(id)
  ),
  teamMembersByTeamId: (st) => st.teamMembersByTeamId,
  teamStatsByID: (st) => st.teamStatsByID,
};

const mutations: MutationTree<TeamState> = {
  setUnitsAndTeams(st: TeamState, payload: Organization) {
    const units: Array<Team> = [];
    const teams: Array<Team> = [];
    const unitsById = new Map<string, Team>();
    const teamsById = new Map<string, Team>();

    payload.teams.forEach((t) => {
      const teamInstance = plainToClass(Team, t);

      if (teamInstance.type === TEAM_TYPE.ORGANIZATIONAL_TEAM) {
        units.push(teamInstance);
        unitsById.set(teamInstance.id!, teamInstance);
      }
      if (teamInstance.type === TEAM_TYPE.VIRTUAL_TEAM) {
        teams.push(teamInstance);
        teamsById.set(teamInstance.id!, teamInstance);
      }
    });
    st.organizationalUnits = units;
    updateOrgByID();
    st.teams = teams;
    updateTeamsByID();
  },
  addNewVirtualTeam(st: TeamState, team: Team) {
    st.teams.push(team);
    st.teams = classToClass(st.teams);
    updateTeamsByID();
  },
  addNewOrganizationalTeam(st: TeamState, team: Team) {
    st.organizationalUnits.push(team);
    st.organizationalUnits = classToClass(st.organizationalUnits);
    updateOrgByID();
  },
  updateVirtualTeam(st: TeamState, team: Team) {
    st.teams = st.teams.map((t: Team) => (t.id === team.id ? team : t));
    updateTeamsByID();
  },
  updateOrganizationalTeam(st: TeamState, team: Team) {
    st.organizationalUnits = st.organizationalUnits.map((t: Team) => (t.id === team.id ? team : t));
    updateOrgByID();
  },
  removeVirtualTeam(st: TeamState, teamId: string) {
    st.teams = st.teams.filter((_: Team) => _.id !== teamId);
    updateTeamsByID();
  },
  removeOrganizationalTeam(st: TeamState, teamId: string) {
    st.organizationalUnits = st.organizationalUnits.filter((_: Team) => _.id !== teamId);
    updateOrgByID();
  },
  addTeamSubscription(
    st: TeamState,
    payload: { teamId: string; users: Array<string>; level: number }
  ) {
    const usersVsLevel = (st.teamMembersByTeamId.has(payload.teamId)
      && st.teamMembersByTeamId.get(payload.teamId))
      || new Map<string, number>();
    payload.users.forEach((u) => usersVsLevel.set(u, payload.level));

    st.teamMembersByTeamId.set(payload.teamId, usersVsLevel);
    updateTeamMembersByID();
  },
  removeUserAsTeamMember(st: TeamState, payload: { teamId: string; userId: string }) {
    if (!st.teamMembersByTeamId || !st.teamMembersByTeamId.has(payload.teamId)) {
      return;
    }
    const users = st.teamMembersByTeamId.get(payload.teamId)!;
    users.delete(payload.userId);

    st.teamMembersByTeamId.set(payload.teamId, users);
    updateTeamMembersByID();
  },
  saveTeamStats(st: TeamState, teamStats: Map<string, StatsData>) {
    st.teamStatsByID = teamStats;
  },
  updateTeamStats(st: TeamState, payload: { teamId: string }) {
    if (!st.teamStatsByID.has(payload.teamId)) {
      return;
    }
    const allTeamMembers = Array.from(st.teamMembersByTeamId.get(payload.teamId)!.entries());
    const owners = allTeamMembers.filter((member) => member[1] === GaleraRoles.OwnerUserLevel);
    const members = allTeamMembers.filter((member) => member[1] === GaleraRoles.MemberUserLevel);

    const stats = st.teamStatsByID.get(payload.teamId)!;
    stats.numberOfOwners = owners.length;
    stats.numberOfMembers = members.length;
    st.teamStatsByID = classToClass(st.teamStatsByID.set(payload.teamId, stats));
  },
};

const actions: ActionTree<TeamState, any> = {
  setUnitsAndTeams({ commit }, org: Organization) {
    commit('setUnitsAndTeams', org);
  },

  async createTeam({ commit }, newTeam: Team): Promise<Team> {
    const team = await TeamService.createTeam(newTeam);
    const mutation = team.type === TEAM_TYPE.ORGANIZATIONAL_TEAM
      ? 'addNewOrganizationalTeam'
      : 'addNewVirtualTeam';
    commit(mutation, team);
    return Promise.resolve(team);
  },

  async updateTeam({ commit }, updatedTeam: Team): Promise<Team> {
    const team = await TeamService.updateTeam(updatedTeam);
    const mutation = team.type === TEAM_TYPE.ORGANIZATIONAL_TEAM
      ? 'updateOrganizationalTeam'
      : 'updateVirtualTeam';
    commit(mutation, team);
    return Promise.resolve(team);
  },

  async removeTeam({ commit }, team: Team): Promise<string> {
    const removedTeamId = await TeamService.removeTeam(team);
    const mutation = team.type === TEAM_TYPE.ORGANIZATIONAL_TEAM
      ? 'removeOrganizationalTeam'
      : 'removeVirtualTeam';
    commit(mutation, team.id);
    return Promise.resolve(removedTeamId);
  },

  async processTeamSubscriptions(
    { dispatch },
    payload: { memberActions: Map<string, TeamMemberAction>; teamId: string }
  ): Promise<Map<string, TeamMemberAction>> {
    const { teamId, memberActions } = payload;

    await Promise.all(Array.from(memberActions.values())
      .map((action) => {
        if (action.action < 0) {
          return dispatch('removeTeamMember', {
            teamId,
            userIds: [action.user.id],
            level: action.level
          });
        }
        if (action.action > 0) {
          return dispatch('addTeamMember', {
            teamId,
            userIds: [action.user.id],
            level: action.level
          });
        }
        return false;
      }));
    return Promise.resolve(payload.memberActions);
  },

  async addTeamMember(
    { commit },
    payload: { teamId: string; userIds: Array<string>; level: number }
  ): Promise<Array<string>> {
    const type = payload.level === GaleraRoles.MemberUserLevel
      ? MEMBER_TYPE.TEAM_MEMBER
      : MEMBER_TYPE.TEAM_OWNER;
    const allUsersAdded = await Promise.all(payload.userIds.map((uId) => (
      TeamService.addTeamMember(payload.teamId, uId, type)
    )));
    commit('addTeamSubscription', {
      teamId: payload.teamId,
      users: allUsersAdded,
      level: payload.level
    });
    commit('updateTeamStats', { teamId: payload.teamId });
    return Promise.resolve(allUsersAdded);
  },

  async removeTeamMember(
    { commit },
    payload: { teamId: string; userIds: Array<string>; level: number }
  ): Promise<Array<string>> {
    const usersRemoved = await Promise.all(payload.userIds.map((uId: string) => (
      TeamService.removeTeamMember(payload.teamId, uId)
    )));
    usersRemoved.forEach((uId: string) => {
      commit('removeUserAsTeamMember', {
        teamId: payload.teamId,
        userId: uId
      });
      commit('updateTeamStats', { teamId: payload.teamId });
    });

    return Promise.resolve(usersRemoved);
  },

  async fetchTeamMembers(
    { commit, state: teamState },
    teamId: string
  ): Promise<Map<string, number>> {
    if (teamState.teamMembersByTeamId.has(teamId)) {
      return Promise.resolve(teamState.teamMembersByTeamId.get(teamId)!);
    }
    const allMembers: Map<string, number> = await TeamService.fetchTeamMembers(teamId);
    const onlyExpertsAndMembers = new Map<string, number>();
    allMembers.forEach((level, userId) => {
      if (level > 3200) {
        commit('addTeamSubscription', {
          teamId,
          users: [userId],
          level
        });
        onlyExpertsAndMembers.set(userId, level);
      }
    });
    return Promise.resolve(onlyExpertsAndMembers);
  },

  async fetchTeamStats(
    { commit, state: teamState },
    teamTargets: Array<string>
  ): Promise<Map<string, StatsData>> {
    const newTargets = teamTargets.filter((_) => !teamState.teamStatsByID.has(_));
    if (newTargets.length) {
      const teamStats: Map<string, StatsData> = await TeamService.fetchTeamStats(teamTargets);
      commit('saveTeamStats', teamStats);
      return Promise.resolve(teamStats);
    }
    return Promise.resolve(new Map<string, StatsData>(teamTargets.map((t) => {
      const stats = teamState.teamStatsByID?.get(t) || new StatsData();
      return [t!, stats];
    })));
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
