import { ActionTree, GetterTree, MutationTree } from 'vuex';
import featureService from '../../services/FeaturesService';
import FEATURE from '../../model/Feature';
import { FeatureState } from '../../types/FeatureState';

const state: FeatureState = {
  features: []
};

const getters: GetterTree<FeatureState, any> = {
  features: (st) => st.features,
  isFeatureEnabled: (st) => (feature: FEATURE) => st.features.includes(feature)
};

const mutations: MutationTree<FeatureState> = {
  setFeatures(st: FeatureState, features: Array<FEATURE>) {
    st.features = features;
  }
};

const actions: ActionTree<FeatureState, any> = {
  async fetchFeatures({ commit }): Promise<Array<FEATURE>> {
    const features: Array<FEATURE> = await featureService.fetchFeatures();
    commit('setFeatures', features);
    return Promise.resolve(features);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
