














import 'reflect-metadata';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import User from '@improve/common-utils/src/model/User';
import NavigationDrawer from '@improve/common-components/src/components/admin/NavigationDrawer.vue';
import Snackbar from '@improve/common-components/src/components/widgets/Snackbar.vue';
import Header from '@/components/core/Header.vue';
import AppBody from '@/components/core/AppBody.vue';
import Footer from '@/components/core/Footer.vue';

@Component({
  components: {
    NavigationDrawer,
    Header,
    AppBody,
    Snackbar,
    Footer
  }
})
export default class App extends Vue {
  @Getter currentUser?: User;

  navigationOptions = [
    {
      title: this.$t('menu.users').toString(),
      icon: 'fa-user',
      value: 'Users'
    },
    {
      title: this.$t('menu.teams').toString(),
      icon: 'fa-user-friends',
      value: 'Teams'
    },
    {
      title: this.$t('menu.topics').toString(),
      icon: 'fa-comment-alt-lines',
      value: 'Topics'
    },
    {
      title: this.$t('menu.improves').toString(),
      icon: 'fa-lightbulb',
      value: 'Improves'
    },
    {
      title: this.$t('menu.assessments').toString(),
      icon: 'fa-question-circle',
      value: 'Assessments',
      refresh: true
    }
  ];
}
