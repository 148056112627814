import { plainToClass } from 'class-transformer';
import Comment from '../model/Comment';
import Reaction from '../model/Reaction';
import ReactionBoard from '../model/ReactionBoard';
import QuetalResponse from '../model/QuetalResponse';
import {
  doSecurePostRequest, doSecureGetRequest, doSecureDeleteRequest
} from './ApiService';
import CommentTargetType from '../types/CommentTargetType';

// =================================================================
// APIs for entity specific features
// =================================================================
function fetchReactionBoardsForTickets(
  canonicalIds: Array<string>,
  iC: boolean,
  iR: boolean,
  iT?: boolean
): Promise<Map<string, ReactionBoard>> {
  const url = `/ticket/fetchBoards?comments=${iC}&&reactions=${iR}&&tags=${iT}`;
  return doSecurePostRequest<Map<string, ReactionBoard>>(url, { targets: canonicalIds });
}

// =================================================================
// APIs for all Comment features
// =================================================================
function createComment(
  comment: string,
  targetId: string,
  author: string,
  targetType: CommentTargetType = CommentTargetType.TICKET
): Promise<Comment> {
  const url = `/quetal/comments/${targetId}/comment/${targetType}`;
  return doSecurePostRequest<Comment>(url, { content: comment, author })
    .then((cmnt) => plainToClass(Comment, cmnt));
}

function fetchCommentById(targetId: string, commentId: string): Promise<Comment> {
  const url = `/quetal/comments/${targetId}/comment/${commentId}`;
  return doSecureGetRequest<Comment>(url).then((cmnt) => plainToClass(Comment, cmnt));
}

function updateComment(targetId: string, commentId: string, comment: Comment): Promise<Comment> {
  const url = `/quetal/comments/${targetId}/comment/${commentId}`;
  return doSecurePostRequest<Comment>(url, comment)
    .then((cmnt) => plainToClass(Comment, cmnt));
}

function deleteComment(targetId: string, commentId: string): Promise<Comment> {
  const url = `/quetal/comments/${targetId}/comment/${commentId}`;
  return doSecureDeleteRequest<Comment>(url).then((cmnt) => plainToClass(Comment, cmnt));
}

function fetchCommentsForTarget(targetId: string): Promise<Array<Comment>> {
  const url = `/quetal/comments/${targetId}/comments`;
  return doSecureGetRequest<Array<Comment>>(url)
    .then((cmnt) => plainToClass(Comment, cmnt));
}

// =================================================================
// APIs for all Reaction features
// =================================================================
function fetchReactions(targetId: string): Promise<Array<Reaction>> {
  const url = `/quetal/reactions/${targetId}/reaction`;
  return doSecureGetRequest<Array<Reaction>>(url)
    .then((react) => plainToClass(Reaction, react));
}

function addReaction(targetId: string, userId: string, reactionKey: string): Promise<Reaction> {
  const url = `/quetal/reactions/${targetId}/reaction/${userId}/${reactionKey}`;
  return doSecurePostRequest<Reaction>(url).then((react) => plainToClass(Reaction, react));
}

function removeReaction(targetId: string, userId: string, reactionKey: string): Promise<string> {
  const url = `/quetal/reactions/${targetId}/reaction/${userId}/${reactionKey}`;
  return doSecureDeleteRequest<QuetalResponse>(url).then((resp) => resp.data);
}

// =================================================================
// APIs for all Comment features
// =================================================================

export default {
  fetchReactionBoardsForTickets,

  createComment,
  fetchCommentById,
  updateComment,
  deleteComment,
  fetchCommentsForTarget,

  fetchReactions,
  addReaction,
  removeReaction
};
