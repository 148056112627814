export default class UserMetaInfo {
  constructor() {
    this.about = null;
    this.jobTitle = null;
    this.phoneNumber = null;
    this.linkedInUrl = null;
  }

  about: string | null;

  jobTitle: string | null;

  phoneNumber: string | null;

  linkedInUrl: string | null;
}
