import { plainToClass } from 'class-transformer';

export interface TokenPayload {
  sub: string;
  aud: string;
  roles: (string)[];
  iss: string;
  exp: number;
}

export default class UserAuthObject {
  id: string;

  context: string;

  token: string;

  roles: string[] = [];

  authenticated: boolean | null = null;

  authorities: Array<{ role: string }> = [];

  constructor(
    id: string,
    context: string,
    token: string,
    roles: Array<string>,
    authenticated: boolean,
    authorities: Array<{ role: string }>
  ) {
    this.id = id;
    this.context = context;
    this.token = token;
    this.roles = roles;
    this.authenticated = authenticated;
    this.authorities = authorities;
  }

  static createFromToken(token: string): UserAuthObject {
    const {
      sub: id,
      roles,
      aud: context
    } = UserAuthObject.decodeToken(token);

    return plainToClass(UserAuthObject, {
      id,
      context,
      token,
      roles,
      authenticated: true,
      authorities: roles.map((role: string) => ({ role }))
    });
  }

  static decodeToken(token: string): TokenPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  }
}
