import Reaction from './Reaction';

export default class Comment {
  constructor(
    id: string | null,
    context: string,
    author: string,
    createdAt: Date,
    updatedAt: Date,
    thread: Array<Comment>,
    reactions: Array<Reaction>
  ) {
    this.id = id;
    this.content = context;
    this.author = author;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.thread = thread;
    this.reactions = reactions;
  }

  id: string | null;

  content: string;

  author: string;

  createdAt: Date;

  updatedAt: Date;

  thread: Array<Comment>;

  reactions: Array<Reaction>;
}
