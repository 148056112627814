








import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'AppBody',
  components: {}
})
export default class AppBody extends Vue {
}
