import { plainToClass } from 'class-transformer';
import UserAuthObject from '../model/UserAuthObject';
import RoleType from '../types/RoleType';

const logoutUrl = process.env.VUE_APP_LOGOUT_URL || '/logout';
const authServer = process.env.VUE_APP_AUTHENTICATION_URL;

export const setCurrentAuth = (user: UserAuthObject): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const deleteCurrentAuth = (ssoLogoutUrl = ''): void => {
  // Remove user from local storage
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  const currentUrl = window.location.href;

  // Check if we need to log out using a url
  if (ssoLogoutUrl) {
    window.location.href = ssoLogoutUrl;
    // Check if the logout happens, because of admin login by normal user
  } else if (currentUrl.includes('redirect=')) {
    // Redirect back to login screen with redirect params in tacked
    window.location.href = `${authServer}?${currentUrl.split('?')[1]}`;
  } else if (!currentUrl.includes(logoutUrl)) {
    window.location.href = logoutUrl;
  }
};

export const getCurrentAuth = (): UserAuthObject | null => {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      return plainToClass(UserAuthObject, JSON.parse(user.toString()) as UserAuthObject);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const isAuthenticated = (): boolean => !!localStorage.getItem('user');

export const userIsAdmin = (user: UserAuthObject): boolean | undefined => user?.roles
  .includes(RoleType.SUPER_ADMIN) || user?.roles.includes(RoleType.ADMIN);

export const authHeader = (): { Authorization?: string } => {
  const auth = getCurrentAuth();

  if (auth && auth.token) {
    return {
      Authorization: `Bearer ${auth.token}`
    };
  }
  return {};
};
