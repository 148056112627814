import { Type } from 'class-transformer';
import ImproveTicketStatus from './ImproveTicketStatus';

export default class TicketStats {
  constructor(byStatus: Map<string, number> = new Map<string, number>(), total = 0) {
    this.byStatus = byStatus;
    this.total = total;
  }

  @Type(() => Map)
  byStatus: Map<string, number>;

  total: number;

  get totalNonArchived(): number {
    return this.total - (this.byStatus.get(ImproveTicketStatus.ARCHIVED) || 0);
  }
}
