




































import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';

@Component({
  name: 'NavigationDrawer'
})
export default class NavigationDrawer extends Vue {
  @Prop({ default: () => [] }) options !: [];

  @Getter drawerIsOpen?: boolean;

  @Getter isFeatureEnabled!: (feature: string) => boolean;

  get enabledOptions(): MenuOption[] {
    // Check if the option's route have a feature and if it's enabled
    return this.options.filter(({ value }) => {
      const { route: { meta } } = this.$router.resolve({
        name: value
      });

      return !meta?.feature || this.isFeatureEnabled(meta?.feature);
    });
  }

  isSelected(value: string): string {
    const { name, meta } = this.$route;
    return [name, meta?.selectedNavigationItem].includes(value)
      ? 'selected'
      : '';
  }

  goToRoute(name: string, refresh = false): void {
    this.$router.push({
      name,
      // Force a fresh of the route
      ...(refresh && this.$route.name === name && {
        query: { refresh: `${(this.$route.query.refresh || 0)}1` }
      })
    });
  }
}
