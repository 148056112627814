








































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import AppLogos from '@improve/common-components/src/components/widgets/AppLogos.vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';

@Component({
  name: 'Header',
  components: {
    AppLogos
  }
})
export default class Header extends Vue {
  @Getter drawerIsOpen?: boolean;

  @Getter isAuthenticated!: boolean;

  @Action logout!: ActionMethod;

  @Action toggleNavigationalDrawer!: ActionMethod;

  get options(): Array<MenuOption> {
    return [
      {
        title: this.$t('menu.featureRequest').toString(),
        onClick: (): void => {
          console.log('featureRequest');
        }
      },
      {
        title: this.$t('menu.bugReport').toString(),
        onClick: (): void => {
          console.log('bugReport');
        }
      },
      {
        title: this.$t('menu.logOut').toString(),
        onClick: (): void => this.logOut()
      }
    ];
  }

  logOut(): void {
    this.logout();
  }

  toggleDrawer(): void {
    this.toggleNavigationalDrawer(!this.drawerIsOpen);
  }

  goHome(): void {
    this.$router.push({ name: 'Dashboard' });
  }
}
