import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { SearchState } from '../../types/SearchState';
import Team from '../../model/Team';
import Ticket from '../../model/Ticket';
import TicketSearchParams from '../../types/TicketSearchParams';

const state: SearchState = {
  foundTeams: [],
  foundTickets: []
};

const getters: GetterTree<SearchState, any> = {
  foundTeams: (st) => st.foundTeams,
  foundTickets: (st) => st.foundTickets
};

const mutations: MutationTree<SearchState> = {
  setFoundTeams(st: SearchState, foundTeams: Array<Team>) {
    st.foundTeams = foundTeams;
  },
  setFoundTickets(st: SearchState, foundTickets: Array<Ticket>) {
    st.foundTickets = foundTickets;
  }
};

const actions: ActionTree<SearchState, any> = {
  async searchTickets({ commit, dispatch, rootGetters }, title: string): Promise<Array<Ticket>> {
    await dispatch('fetchWorkflow');
    const params = new TicketSearchParams();
    params.title = title;
    params.status = rootGetters.activeWorkflowStatuses;
    let tickets = new Array<Ticket>();
    tickets = await dispatch('getAllTickets', params);
    commit('setFoundTickets', tickets);
    return Promise.resolve(tickets);
  },
  async viewAllTickets({ commit, dispatch }, params: TicketSearchParams): Promise<Array<Ticket>> {
    const tickets: Array<Ticket> = await dispatch('getAllTickets', params);
    commit('setFoundTickets', tickets);
    return Promise.resolve(tickets);
  },
  async searchTeams({ commit, rootGetters }, name: string): Promise<void> {
    let teams = new Array<Team>();
    const allTeams = teams!.concat(rootGetters.organizationalUnits!);
    teams = (allTeams!.filter((_: Team) => _.name?.toLowerCase()
      .includes(name.toLowerCase())));
    commit('setFoundTeams', teams);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
