export default class TopicMetaData {
  constructor(
    displayName: string | null = null,
    color: string | null = null,
    description: string | null = null
  ) {
    this.displayName = displayName;

    this.color = color;

    this.description = description;
  }

  displayName: string | null;

  color: string | null;

  description: string | null;
}
