import SortDirection from './SortDirection';

export default class UserSearchParams {
  constructor() {
    this.page = 0;
    this.size = 1000;
    this.sort = SortDirection.ASC;
  }

  name: string | null = null;

  roles: Array<string> | null = null;

  team: string | null = null;

  unit: string | null = null;

  page: number;

  size: number;

  sort: SortDirection;

  buildUrl(): string {
    const params = [];
    // Set search params for name
    if (this.name && this.name.length) {
      params.push(`name=${this.name}`);
    }

    // Set search params for roles
    if (this.roles && this.roles.length) {
      params.push(this.roles.map((_) => `roles=${_}`).join('&'));
    }

    // Set search params for team
    if (this.team && this.team.length) {
      params.push(`team=${this.team}`);
    }

    // Set search params for unit
    if (this.unit && this.unit.length) {
      params.push(`unit=${this.unit}`);
    }

    // Set search params for page
    params.push(`page=${this.page}`);

    // Set search params for size
    params.push(`size=${this.size}`);

    // Set search params for sort
    params.push(`sort=${this.sort}`);

    return params.join('&');
  }
}
