import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { classToClass } from 'class-transformer';
import NodeType from '../../types/NodeType';
import Topic, { TopicMemberType } from '../../model/Topic';
import { TopicState } from '../../types/TopicState';
import TopicService from '../../services/TopicService';
import StatsData from '../../model/StatsData';

const state: TopicState = {
  organizationTopics: [],
  userSubscribedTopics: [],
  organizationTopicsById: new Map<string, Topic>(),
  topicStatsById: new Map<string, StatsData>(),
  topicUserSubscription: new Map<string, Map<string, number>>()
};

const getters: GetterTree<TopicState, any> = {
  organizationTopics: (st) => st.organizationTopics,
  userSubscribedTopics: (st) => st.userSubscribedTopics,
  organizationTopicsById: (st) => (id: string) => classToClass(st.organizationTopicsById?.get(id)),
  topicStatsById: (st) => st.topicStatsById
};

const updateOrgTopicsById = () => {
  state.organizationTopicsById = new Map(state.organizationTopics
    .map((i: Topic) => [i.id!, i]));
};

const mutations: MutationTree<TopicState> = {
  setOrgTopics(st: TopicState, payload: Array<Topic>) {
    st.organizationTopics = payload;
    updateOrgTopicsById();
  },
  setUserSubscribedTopics(st: TopicState, payload: Array<Topic>) {
    st.userSubscribedTopics = payload;
  },
  addTopic(st: TopicState, newTopic: Topic) {
    st.organizationTopics = [...(st.organizationTopics || []), newTopic];
    updateOrgTopicsById();
  },
  updateTopic(st: TopicState, newTopic: Topic) {
    st.organizationTopics = st.organizationTopics
      ? st.organizationTopics.map((_: Topic) => (_.id === newTopic.id ? newTopic : _))
      : [];
    updateOrgTopicsById();
  },
  saveTopicStats(st: TopicState, topicStats: Map<string, StatsData>) {
    if (!st.topicStatsById) {
      st.topicStatsById = new Map<string, StatsData>();
    }
    topicStats.forEach((stat, topicId) => {
      st.topicStatsById!.set(topicId, stat);
    });
    st.topicStatsById = classToClass(st.topicStatsById);
  },
  deleteTag(st: TopicState, deletedTopicId: string) {
    st.organizationTopics = st.organizationTopics
      ? st.organizationTopics.filter((_: Topic) => _.id !== deletedTopicId)
      : [];
    updateOrgTopicsById();
  },
  addSubscription(st: TopicState, payload: { topicId: string; userId: string; level: number }) {
    let subs = st.topicUserSubscription.get(payload.topicId);
    subs = subs ? subs.set(payload.userId, payload.level) : new Map<string, number>();
    st.topicUserSubscription = classToClass(st.topicUserSubscription
      .set(payload.topicId, subs));
  },
  removeSubscription(st: TopicState, payload: { topicId: string; userId: string }) {
    const subs = st.topicUserSubscription?.get(payload.topicId);
    if (subs) {
      subs.delete(payload.userId);
      st.topicUserSubscription = classToClass(st.topicUserSubscription
        .set(payload.topicId, subs));
    }
  },
  saveTopicSubscriptions(st: TopicState, payload: { topicId: string; subs: Map<string, number> }) {
    st.topicUserSubscription = classToClass(st.topicUserSubscription
      .set(payload.topicId, payload.subs));
  }
};

const actions: ActionTree<TopicState, any> = {

  async createTopic({ commit }, topic: Topic): Promise<Topic> {
    const newTopic = await TopicService.createTopic(topic);
    commit('addTopic', newTopic);
    return Promise.resolve(newTopic);
  },

  async updateTopic({ commit }, topic: Topic): Promise<Topic> {
    const updatedTopic = await TopicService.updateTopic(topic);
    commit('updateTopic', updatedTopic);
    return Promise.resolve(updatedTopic);
  },

  async deleteTopic({ commit }, topic: Topic): Promise<string> {
    const deletedTopicId = await TopicService.deleteTopic(topic);
    commit('deleteTag', deletedTopicId);
    return Promise.resolve(deletedTopicId);
  },

  async addUserSubscription(
    { commit },
    payload: { users: Array<string>; topic: Topic }
  ) {
    const promises = payload.users.map((userId) => TopicService.addUserSubscription(
      payload.topic, userId, TopicMemberType.TAG_EXPERT
    ));
    const addedUserIds = await Promise.all(promises);
    addedUserIds.forEach((uId: string) => {
      commit('addSubscription', {
        userId: uId,
        topicId: payload.topic.id,
        level: TopicMemberType.TAG_EXPERT_LEVEL
      });
    });
    return Promise.resolve(addedUserIds);
  },

  async removeUserSubscription(
    { commit },
    payload: { users: Array<string>; topic: Topic }
  ) {
    const removedUserIds = await Promise.all(payload.users.map((userId) => TopicService
      .removeUserSubscription(payload.topic, userId)));
    removedUserIds.forEach((uId: string) => {
      commit('removeSubscription', { userId: uId, topicId: payload.topic.id });
    });
    return Promise.resolve(removedUserIds);
  },

  async fetchTopicSubscriptions(
    { commit },
    topic: Topic,
  ): Promise<Map<string, number>> {
    const subs = await TopicService.fetchTopicSubscriptions(topic);
    commit('saveTopicSubscriptions', subs);
    return subs;
  },

  async fetchTopicsByContext({ commit, state: st }): Promise<Array<Topic>> {
    if (st.organizationTopics?.length) {
      return Promise.resolve(st.organizationTopics);
    }
    const topics = await TopicService.fetchTopicsByContext();
    commit('setOrgTopics', topics.filter((t) => t.tagType === NodeType.TOPIC));
    return Promise.resolve(topics);
  },

  async fetchSubscribedTopics({ commit, state: st }): Promise<Array<Topic>> {
    if (st.userSubscribedTopics.length) {
      return Promise.resolve(st.userSubscribedTopics);
    }
    const topics = await TopicService.fetchSubscribedTopics();
    commit('setUserSubscribedTopics', topics.filter((t) => t.tagType === NodeType.TOPIC));
    return Promise.resolve(topics);
  },

  async fetchTopicsByTarget({ rootGetters }, targetId: string): Promise<Array<Topic>> {
    return TopicService.fetchTopicsByTarget(
      rootGetters.currentOrganization.context,
      targetId
    );
  },

  async fetchTopicStatsByBatch(
    { commit },
    targets: Array<Topic>
  ): Promise<Map<string, StatsData>> {
    const topicStats = await TopicService.fetchTopicStatsByBatch(targets);
    commit('saveTopicStats', topicStats);
    return topicStats;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
