import Vue from 'vue';
import InlineSvg, { InlineSvgPlugin } from 'vue-inline-svg';
import i18n from '@improve/common-utils/src/i18n';
import initializeVeeValidate from '@improve/common-utils/src/plugins/vee-validate';
import initTagManager from '@improve/common-utils/src/plugins/googleTagManager';
import initSentry from '@improve/common-utils/src/plugins/sentry';
import DayjsPlugin from '@improve/common-utils/src/plugins/dayjs';
import vuetify from '@improve/common-utils/src/plugins/vuetify';
import '@improve/common-utils/src/plugins/polyfill';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

const isDev = process.env.NODE_ENV === 'development';

Vue.config.productionTip = isDev;

initSentry('improve-admin');
initializeVeeValidate();
initTagManager(router, true);

Vue.use(DayjsPlugin);
Vue.use(InlineSvgPlugin);
Vue.component('inline-svg', InlineSvg);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
