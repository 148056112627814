import { plainToClass } from 'class-transformer';
import QuetalResponse from '../model/QuetalResponse';
import StatsData from '../model/StatsData';
import Topic, { TopicMemberType } from '../model/Topic';

import {
  doSecurePostRequest,
  doSecureGetRequest,
  doSecureDeleteRequest,
  doSecurePutRequest
} from './ApiService';

function fetchTopicsByContext(): Promise<Array<Topic>> {
  const url = '/quetal/tags/fetchTagsByContext';
  return doSecureGetRequest<Array<Topic>>(url)
    .then((topic) => plainToClass(Topic, topic));
}

function fetchSubscribedTopics(): Promise<Array<Topic>> {
  const url = '/quetal/tags/fetchSubscribedTags';
  return doSecureGetRequest<Array<Topic>>(url)
    .then((topic) => plainToClass(Topic, topic));
}

function createTopic(topic: Topic): Promise<Topic> {
  const url = '/quetal/tags';
  return doSecurePostRequest<Topic>(url, topic)
    .then((newTopic) => plainToClass(Topic, newTopic));
}

function updateTopic(topic: Topic): Promise<Topic> {
  const url = `/quetal/tags/${topic.tagName}/${topic.tagType}`;
  return doSecurePutRequest<Topic>(url, topic)
    .then((newTopic) => plainToClass(Topic, newTopic));
}

function deleteTopic(topic: Topic): Promise<string> {
  const url = `/quetal/tags/${topic.tagName}/${topic.tagType}`;
  return doSecureDeleteRequest<QuetalResponse>(url)
    .then((newTopic) => newTopic.data);
}

function addUserSubscription(topic: Topic, userId: string, type: TopicMemberType): Promise<string> {
  const url = `/quetal/tags/${topic.tagName}/${topic.tagType}/subscribeUser/${userId}/${type}`;
  return doSecurePostRequest<string>(url);
}

function removeUserSubscription(topic: Topic, userId: string): Promise<string> {
  const url = `/quetal/tags/${topic.tagName}/${topic.tagType}/unSubscribeUser/${userId}`;
  return doSecureDeleteRequest<string>(url);
}

function fetchTopicSubscriptions(topic: Topic): Promise<Map<string, number>> {
  const url = `/quetal/tags/${topic.tagName}/${topic.tagType}/subscriptions`;
  return doSecureGetRequest<any>(url)
    .then((subs) => new Map(Object.keys(subs)
      .map((k) => [k!, parseInt(subs[k], 10)])));
}

function fetchTopicsByTarget(context: string, targetId: string): Promise<Array<Topic>> {
  const url = `/quetal/tags/${targetId}/tags`;
  return doSecureGetRequest<Array<Topic>>(url)
    .then((topic) => plainToClass(Topic, topic));
}

function fetchTopicStatsByBatch(
  targets: Array<Topic>,
  cached = true
): Promise<Map<string, StatsData>> {
  const url = `/stats/fetchTagStatsByBatch?cached=${cached}`;
  return doSecurePostRequest<any>(url, { targets })
    .then((topicStats) => {
      const allTeamStats: Map<string, StatsData> = new Map<string, StatsData>();
      Object.keys(topicStats).forEach((userId: string) => {
        allTeamStats.set(userId, plainToClass(StatsData, topicStats[userId]));
      });
      return allTeamStats;
    });
}

export default {
  fetchTopicSubscriptions,
  fetchTopicsByContext,
  fetchSubscribedTopics,
  fetchTopicsByTarget,
  fetchTopicStatsByBatch,
  createTopic,
  updateTopic,
  deleteTopic,
  addUserSubscription,
  removeUserSubscription
};
