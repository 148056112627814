import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { Route } from 'vue-router';
import VueRouter from '../router/VueRouteOverride';

const initTagManager = (router: VueRouter, isAdmin: boolean): void => {
  const id = isAdmin
    ? process.env.VUE_APP_GA_ADMIN : process.env.VUE_APP_GA;
  Vue.use(VueGtag, {
    config: { id },
    pageTrackerTemplate(to: Route) {
      return {
        page_title: to.name,
        page_path: to.path,
        page_location: to.query.page,
        send_page_view: true
      };
    }
  }, router);
};

export default initTagManager;
