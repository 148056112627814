

















import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { OrganizationCustomization } from '@improve/common-utils/src/types/ProductCustomization';

@Component({
  name: 'AppLogos'
})
export default class AppLogos extends Vue {
  @Getter organizationCustomization?: OrganizationCustomization;
}
