import Vue from 'vue';
import {
  extend, localize, ValidationObserver, ValidationProvider
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import locales from './vee-validate-i18n';
import i18n from '../i18n';

const initializeVeeValidate = (): void => {
  localize(locales);
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(rules).forEach((rule: string) => extend(rule, rules[rule]));

  extend('number_required', {
    ...rules.required,
    message: i18n.t('error.numberRequired').toString()
  });
};
export default initializeVeeValidate;
