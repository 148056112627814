import TicketDelegationType from '../types/TicketDelegationType';

export enum TEAM_TYPE {
  ORGANIZATIONAL_TEAM = 'ORGANIZATIONAL_TEAM',
  VIRTUAL_TEAM = 'VIRTUAL_TEAM'
}

export enum MEMBER_TYPE {
  TEAM_MEMBER = 'TEAM_MEMBER',
  TEAM_OWNER = 'TEAM_OWNER'
}

export default class Team {
  id: string | null;

  name: string;

  description: string;

  type: TEAM_TYPE;

  createdBy: string | undefined;

  membersAmount: number | undefined;

  ticketsAmount: number | undefined;

  ownersAmount: number | undefined;

  constructor(id: string | null, name: string, description: string, type: TEAM_TYPE) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
  }

  getDelegateId(): string {
    return `${TicketDelegationType.TEAM}:${this.id}`;
  }
}
