import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { classToClass } from 'class-transformer';
import QuetalService from '../../services/QuetalService';
import ReactionBoard from '../../model/ReactionBoard';
import ReactionEvent from '../../model/ReactionEvent';
import Reaction from '../../model/Reaction';
import Comment from '../../model/Comment';
import User from '../../model/User';
import { QuetalState } from '../../types/QuetalState';

type ReactionBoardPayload = {
  targets: Array<string>;
  fetchComments: boolean;
  fetchReactions: boolean;
  fetchTags: boolean;
}

const state: QuetalState = {
  reactionBoardsById: new Map<string, ReactionBoard>()
};

const getters: GetterTree<QuetalState, any> = {
  reactionBoardsById: (st) => st.reactionBoardsById
};

const reactionsByKey = (
  targetId: string,
  reactionKey: string
): Reaction | undefined => {
  const board = state.reactionBoardsById.get(targetId);
  return board && board.reactions.find((_) => _.reactionKey === reactionKey);
};

const hasUserReactedToTarget = (
  targetId: string,
  userId: string,
  reactionKey: string
): ReactionEvent | undefined => {
  const reaction = reactionsByKey(targetId, reactionKey);
  return reaction && reaction.events.find((_) => _.author === userId);
};

function updateStoreReactionBoards() {
  state.reactionBoardsById = classToClass(state.reactionBoardsById);
}

const mutations: MutationTree<QuetalState> = {
  setTicketReactionBoards(st: QuetalState, payload: any) {
    Object.keys(payload).forEach((ticketId) => {
      st.reactionBoardsById.set(ticketId, payload[ticketId]);
    });
    updateStoreReactionBoards();
  },
  addTicketComment(st: QuetalState, payload: { ticketId: string; comment: Comment }) {
    if (st.reactionBoardsById.has(payload.ticketId)) {
      st.reactionBoardsById.get(payload.ticketId)?.comments.push(payload.comment);
    }
    updateStoreReactionBoards();
  },
  setReactionToTicket(
    st: QuetalState,
    payload: { ticketId: string; userId: string; reactionKey: string }
  ) {
    const board = st.reactionBoardsById.get(payload.ticketId);
    if (board) {
      const reaction = reactionsByKey(payload.ticketId, payload.reactionKey);
      const userReacted = hasUserReactedToTarget(
        payload.ticketId,
        payload.userId,
        payload.reactionKey
      );
      if (userReacted) {
        return;
      }
      const event = new ReactionEvent(payload.userId, new Date(), 1);
      if (reaction) {
        reaction.events.push(event);
      } else {
        board.reactions.push(new Reaction(payload.reactionKey, [event]));
      }
    }
    updateStoreReactionBoards();
  },
  setUnReactionToTicket(
    st: QuetalState,
    payload: { ticketId: string; reactionKey: string; userId: string }
  ) {
    const reactions = st.reactionBoardsById.get(payload.ticketId)?.reactions;
    const reaction = reactions?.find((_: Reaction) => _.reactionKey === payload.reactionKey);
    if (reaction) {
      reaction.events = reaction.events.filter((e: ReactionEvent) => e.author !== payload.userId);
    }
    updateStoreReactionBoards();
  }
};

const actions: ActionTree<QuetalState, any> = {

  async createTicketComment(
    { commit, rootState },
    payload: { ticketId: string; comment: string }
  ): Promise<Comment> {
    const user: User = rootState.user.currentUser;
    const newCmnt = await QuetalService.createComment(
      payload.comment,
      payload.ticketId,
      user.id!
    );
    commit('addTicketComment', {
      ticketId: payload.ticketId,
      comment: newCmnt
    });
    return Promise.resolve(newCmnt);
  },

  reactToTicket(
    { commit, rootState },
    payload: { ticketId: string; reactionKey: string }
  ): Promise<Reaction> {
    const user: User = rootState.user.currentUser;

    if (hasUserReactedToTarget(payload.ticketId, user.id!, payload.reactionKey)) {
      return Promise.resolve(new Reaction(
        payload.reactionKey,
        [new ReactionEvent(user.id!, new Date(), 1)]
      ));
    }
    commit('setReactionToTicket', {
      ticketId: payload.ticketId,
      userId: user.id,
      reactionKey: payload.reactionKey
    });
    return QuetalService.addReaction(
      payload.ticketId,
      user.id!,
      payload.reactionKey
    );
  },

  unReactToTicket(
    { commit, rootState },
    payload: { ticketId: string; reactionKey: string }
  ): Promise<string> {
    const user: User = rootState.user.currentUser;
    commit('setUnReactionToTicket', {
      ticketId: payload.ticketId,
      userId: user.id,
      reactionKey: payload.reactionKey
    });
    return QuetalService.removeReaction(
      payload.ticketId,
      user.id!,
      payload.reactionKey
    );
  },

  async fetchReactionBoardsForTickets(
    { commit, dispatch, state: qState },
    payload: ReactionBoardPayload
  ): Promise<Map<string, ReactionBoard>> {
    const newTargets = payload.targets.filter((_) => !qState.reactionBoardsById.has(_));
    if (newTargets.length) {
      const boards = await QuetalService.fetchReactionBoardsForTickets(
        newTargets,
        payload.fetchComments,
        payload.fetchReactions,
        payload.fetchTags
      );
      commit('setTicketReactionBoards', boards);

      // Get user information for comment authors
      const targetAuthors: Array<string> = [];
      qState.reactionBoardsById.forEach((b) => {
        const commentAuthors = b.comments.map((_) => _.author);
        targetAuthors.push(...commentAuthors);
      });
      await dispatch('fetchUsersByBatch', targetAuthors, { root: true });
      return Promise.resolve(boards);
    }
    return Promise.resolve(new Map(
      payload.targets.map((t) => [t, qState.reactionBoardsById.get(t)!])
    ));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
