import { classToClass } from 'class-transformer';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import TicketService from '../../services/TicketService';
import { TicketState } from '../../types/TicketState';
import TicketStats from '../../types/TicketStats';
import TicketSearchParams from '../../types/TicketSearchParams';
import TicketStatsParams from '../../types/TicketStatsParams';
import Ticket from '../../model/Ticket';
import Transition from '../../model/Transition';
import { TicketStatus } from '../../types/TicketStatus';
import {
  convertVideo, dataUrlToFile, processMultiUpload, removeAttachment, startMultiUpload,
  stopMultiUpload
} from '../../services/FileUploadService';
import { StopMultiUploadResponse } from '../../types/MultiUploadResponse';
import { UploadState } from '../../types/UploadState';
import Task from '../../model/Task';
import TaskUpdateParams from '../../types/TaskUpdateParams';
import TaskStatus from '../../types/TaskStatus';
import { FileModel } from '../../types/FileModel';
import { VUFile } from '../../types/VUFile';
import { UploadDataUrlPayload } from '../../types/UploadDataUrlPayload';

const state: TicketState = {
  tickets: [],
  ticketsByCanonicalId: new Map<string, Ticket>(),
  ticketStats: new TicketStats(),
  assignedTickets: new Map<string, Array<Ticket>>(),
  allTicketsByStatus: new Map<string, Array<Ticket>>()
};

const getters: GetterTree<TicketState, any> = {
  tickets: (st) => st.tickets,
  ticketsByCanonicalId: (st) => st.ticketsByCanonicalId,
  ticketStats: (st) => st.ticketStats,
  assignedTickets: (st) => st.assignedTickets,
  getAssignedTicketsByStatus: (st) => (status: string) => classToClass(
    st.assignedTickets.get(status)
  ) || [],
  getAllTicketsByStatus: (st) => (status: string) => classToClass(
    st.allTicketsByStatus.get(status)
  ) || []
};

const refreshTicketsByCanId = () => {
  // Updates the ticketsByCanonicalId with a new instance of it. So all the components using
  // @Getter ticketsByCanonicalId gets updated. Setting a new item by key doesn't trigger the update
  // of the component.
  state.ticketsByCanonicalId = classToClass(state.ticketsByCanonicalId);
};

const updateTicketsByCanId = (source: Array<Ticket>) => {
  source.forEach((t: Ticket) => state.ticketsByCanonicalId.set(t.canonicalId!, t));
  refreshTicketsByCanId();
};

const updateTicketsByStatus = (source: Array<Ticket>) => {
  source.forEach((ticket) => {
    const oldTickets = state.allTicketsByStatus.get(ticket.status!) || [];
    state.allTicketsByStatus.set(ticket.status!, [...oldTickets, ticket]);
  });

  state.allTicketsByStatus = classToClass(state.allTicketsByStatus);
};

const getFieldValue = (field: string, container: any) => field.split('.')
  .reduce((result, key) => result[key], container);

const changeTicketCollection = (
  st: TicketState,
  payload: { ticket: Ticket; fromStatus: string; toStatus: string; toIndex: number },
  stateType: 'assignedTickets' | 'allTicketsByStatus'
) => {
  let fromTickets = st[stateType].get(payload.fromStatus) || [];
  fromTickets = fromTickets.filter((t) => t.id !== payload.ticket.id);
  const toTickets = st[stateType].get(payload.toStatus) || [];
  toTickets.splice(payload.toIndex, 0, payload.ticket);

  st[stateType].set(payload.fromStatus, fromTickets);
  st[stateType].set(payload.toStatus, toTickets);
  st[stateType] = classToClass(st[stateType]);
};

const mutations: MutationTree<TicketState> = {
  setTickets(st: TicketState, tickets: Array<Ticket>) {
    st.tickets = tickets;
    updateTicketsByCanId(st.tickets);
    updateTicketsByStatus(st.tickets);
  },
  setTicketById(st: TicketState, ticketById: { id: string; ticket: Ticket }) {
    if (!st.ticketsByCanonicalId.has(ticketById.id)) {
      st.tickets = [ticketById.ticket, ...(st.tickets || [])];
      updateTicketsByCanId(st.tickets);
    } else {
      updateTicketsByCanId([ticketById.ticket]);
      const index = st.tickets
        ? st.tickets.findIndex((_: Ticket) => _.canonicalId === ticketById.id)
        : -1;
      if (index >= 0) {
        st.tickets.splice(index, 1, ticketById.ticket);
        st.tickets = classToClass(st.tickets);
      }
    }
    // Update the assignments source
    let tkts = st.assignedTickets.get(ticketById.ticket.status!);
    if (tkts && tkts.length) {
      tkts = tkts.map((_) => (_.id === ticketById.ticket.id ? ticketById.ticket : _));
      st.assignedTickets = classToClass(st.assignedTickets.set(ticketById.ticket.status!, tkts));
    }
  },
  setTicketStats(st: TicketState, stats: TicketStats) {
    st.ticketStats = classToClass(stats);
  },
  setAssignedTicketsByStatus(
    st: TicketState,
    payload: { status: Array<string>; tickets: Array<Ticket> }
  ) {
    const sts = payload.status.join(',');
    let newTickets = payload.tickets.map((t) => {
      st.ticketsByCanonicalId.set(t.canonicalId!, t);
      return st.ticketsByCanonicalId.get(t.canonicalId!)!;
    });
    const oldTickets = st.assignedTickets.has(sts) ? st.assignedTickets.get(sts)! : [];

    // filter out duplicate tickets
    newTickets = newTickets.filter((newTicket) => !oldTickets.some(
      (oldTicket) => oldTicket.id === newTicket.id
    ));
    const allTickets = oldTickets.concat(newTickets);

    st.assignedTickets = classToClass(st.assignedTickets.set(sts, allTickets));
    refreshTicketsByCanId();
  },
  resetAssignedTicketsByStatus(
    st: TicketState,
    payload: { status: Array<string> }
  ) {
    const sts = payload.status.join(',');
    st.assignedTickets.set(sts, []);
  },
  changeAssignedTicketStatus(
    st: TicketState,
    payload: { ticket: Ticket; fromStatus: string; toStatus: string; toIndex: number }
  ) {
    changeTicketCollection(st, payload, 'assignedTickets');
  },
  changeAllTicketsByStatus(
    st: TicketState,
    payload: { ticket: Ticket; fromStatus: string; toStatus: string; toIndex: number }
  ) {
    changeTicketCollection(st, payload, 'allTicketsByStatus');
  }
};

const actions: ActionTree<TicketState, any> = {
  async createTicket({ dispatch }, newTicket: Ticket): Promise<Ticket> {
    const ticket = await TicketService.createTicket(newTicket);
    dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(ticket);
  },

  async getAllTickets({ commit, dispatch }, payload?: TicketSearchParams): Promise<Array<Ticket>> {
    const tickets: Array<Ticket> = await TicketService
      .getAllTickets(payload || new TicketSearchParams());
    commit('setTickets', tickets);
    await dispatch('fetchInformationForTickets', { tickets, tasks: true });
    return Promise.resolve(tickets);
  },

  async getAssignedTickets(
    { commit, dispatch },
    payload?: TicketSearchParams
  ): Promise<Array<Ticket>> {
    const searchBy = payload || new TicketSearchParams();
    if (!searchBy.page) {
      commit('resetAssignedTicketsByStatus', { status: payload?.status || ['ALL'] });
    }
    const tickets = await TicketService.getAssignedTickets(searchBy);
    commit('setAssignedTicketsByStatus', { status: payload?.status || ['ALL'], tickets });
    await dispatch('fetchInformationForTickets', { tickets, tasks: true });
    return Promise.resolve(tickets);
  },

  async getTicketById(
    { dispatch, state: ticketState },
    canonicalId: string
  ): Promise<Ticket> {
    if (ticketState.ticketsByCanonicalId.has(canonicalId)) {
      return Promise.resolve(ticketState.ticketsByCanonicalId.get(canonicalId) as Ticket);
    }
    const ticket = await TicketService.getTicketById(canonicalId);
    dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(ticket);
  },

  async getTicketStats({ commit }, payload?: TicketStatsParams): Promise<TicketStats> {
    const ticketStats = await TicketService.getTicketStats(payload);
    commit('setTicketStats', ticketStats);
    return Promise.resolve(ticketStats);
  },

  async setTicketById(
    { commit, dispatch },
    payload: { id: string; ticket: Ticket }
  ): Promise<Ticket> {
    commit('setTicketById', {
      id: payload.id,
      ticket: payload.ticket
    });

    await dispatch('fetchInformationForTickets', { tickets: [payload.ticket], tasks: true });
    return Promise.resolve(payload.ticket);
  },

  async fetchInformationForTickets(
    { dispatch },
    payload: { tickets: Array<Ticket>; tasks: boolean } = { tickets: [], tasks: true }
  ) {
    const userMap = new Map<string, boolean>();
    const cIds: Array<string> = [];
    payload.tickets.forEach((ticket: Ticket) => {
      userMap.set(ticket.creator!, true);
      userMap.set(ticket.assignee!, true);
      cIds.push(ticket.canonicalId!);
      if (payload.tasks) {
        ticket.tasks.forEach((task: Task) => {
          userMap.set(task.assignee!, true);
        });
      }
    });
    await dispatch('fetchUsersByBatch', Array.from(userMap.keys()), { root: true });
    await dispatch('fetchReactionBoardsForTickets', {
      targets: cIds,
      fetchComments: true,
      fetchReactions: true,
      fetchTags: false
    }, { root: true });
    return Promise.resolve(true);
  },

  async updateTicket({ dispatch }, updatedTicket: Ticket): Promise<Ticket> {
    const ticket = await TicketService.updateTicket(updatedTicket);
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    return Promise.resolve(ticket);
  },

  async updateTasks(
    { dispatch },
    payload: { parent: Ticket; updates: TaskUpdateParams }
  ): Promise<Ticket> {
    const ticket = payload.parent;
    const { added, updated, removed } = payload.updates;

    // If no changes made to tasks, dont proceed
    if (!added.length && !updated.length && !removed.length) {
      return Promise.resolve(ticket);
    }
    const newOnes = await Promise.all(added.map((t) => dispatch('createTicket', t)
      .then((nT) => ({ id: t.id, ticket: nT }))));

    const oldOnes = await Promise.all(updated.map((t) => dispatch('updateTicket', t)
      .then((nT) => ({ id: t.id, ticket: nT }))));

    const updates = [...newOnes, ...oldOnes];
    ticket.tasks = ticket.tasks.map((t) => updates.find((u) => u.id === t.id)?.ticket || t);
    ticket.tasks = ticket.tasks.filter((t) => !removed.find((u) => u.id === t.id));
    const updatedTicket = await TicketService.updateTasks(ticket, ticket.tasks as Array<Task>);
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket: updatedTicket
    });
    return Promise.resolve(ticket);
  },

  async updateTaskCompletion(
    { dispatch },
    payload: { parent: Ticket; complete: boolean }
  ): Promise<Ticket> {
    const nextStatus = payload.complete ? TaskStatus.COMPLETED : TaskStatus.DRAFT;
    const ticket = payload.parent;
    if (ticket.status === nextStatus) {
      return Promise.resolve(payload.parent);
    }

    await dispatch('updateTicketStatus', {
      ticket,
      fromStatus: ticket.status,
      toStatus: nextStatus
    });
    ticket.status = nextStatus;
    return ticket;
  },

  async updateTicketStatus(
    { commit, dispatch },
    payload: { ticket: Ticket; fromStatus: string; toStatus: string; newIndex: number }
  ): Promise<Ticket> {
    const ticket: Ticket = await TicketService.updateTicketStatus(
      payload.ticket.canonicalId!,
      payload.toStatus
    );
    await dispatch('setTicketById', {
      id: ticket.canonicalId,
      ticket
    });
    commit('changeAssignedTicketStatus', {
      ticket,
      fromStatus: payload.fromStatus,
      toStatus: payload.toStatus,
      toIndex: payload.newIndex || 0
    });
    commit('changeAllTicketsByStatus', {
      ticket,
      fromStatus: payload.fromStatus,
      toStatus: payload.toStatus,
      toIndex: payload.newIndex || 0
    });
    return Promise.resolve(ticket);
  },

  async submitNewTicket({ dispatch }, {
    ticket,
    status
  }: { ticket: Ticket; status: TicketStatus }): Promise<Ticket> {
    const newTicket: Ticket = await dispatch('createTicket', ticket);
    return dispatch('updateTicketStatus', {
      cId: newTicket.canonicalId,
      status
    });
  },

  validateStatusTransition(
    { rootGetters },
    payload: { ticket: Ticket; nextStatus: string }
  ): { result: boolean; reason?: string; args?: Array<string> } {
    const transition: Transition = rootGetters.activeTransitions.find((_: Transition) => (
      (_.transitionFrom === payload.ticket.status && _.transitionTo === payload.nextStatus)
      || (_.transitionTo === payload.ticket.status
        && _.transitionFrom === payload.nextStatus
        && _.bidirectional)
    ));
    if (!transition) {
      return { result: false, reason: 'invalid.transition' };
    }
    const invalidFields = transition.requiredFields
      .filter((field) => !getFieldValue(field, payload.ticket));
    if (invalidFields.length) {
      return {
        result: false,
        reason: 'invalid.fields',
        args: invalidFields
      };
    }
    return {
      result: true
    };
  },

  openTicketInPortal({ commit }, canonicalId: string): Promise<void> {
    return Promise.resolve(TicketService.openTicketInPortal(canonicalId));
  },

  openEditTicketInPortal({ commit }, canonicalId: string): Promise<void> {
    return Promise.resolve(TicketService.openEditTicketInPortal(canonicalId));
  },

  async uploadDataUrl({ dispatch }, {
    dataUrl, fileName, objectId, category
  }: UploadDataUrlPayload): Promise<FileModel> {
    const convertedFile = await dataUrlToFile(dataUrl, fileName);

    const file = ({
      name: fileName,
      file: convertedFile,
      type: convertedFile.type,
      size: convertedFile.size
    } as unknown) as VUFile;

    return dispatch('uploadLargeFile', {
      file,
      objectId,
      category,
      inputId: objectId
    });
  },

  async uploadLargeFile({ commit }, {
    inputId, objectId, file, category
  }): Promise<FileModel> {
    const { type: contentType, name: originalFilename, size } = file;
    const { uploadId, key } = await startMultiUpload(objectId, {
      contentType,
      tags: {
        originalFilename, size, contentType
      }
    });
    commit('initUploadState', {
      inputId, objectId, file, uploadId, key, parts: [], progress: 0
    });
    const callBack = (uploadState: UploadState) => commit('setUploadProgress', uploadState);
    const parts: string[] = await processMultiUpload(
      inputId, uploadId, file, key, callBack
    );

    const response: StopMultiUploadResponse = await stopMultiUpload(objectId, {
      category,
      contentType,
      originalFilename,
      uploadId,
      parts,
      key
    });
    commit('removeUploadState', inputId);

    return {
      id: response.file.id,
      url: response.file.url,
      filename: response.file.filename,
      contentSize: response.file.contentSize!,
      internal: response.file.internal,
      category: response.file.category,
      meta: response.file.meta,
      fallbackLocations: response.file.fallbackLocations
    };
  },

  removeFile({ commit }, { ticketId, fileId }): Promise<void> {
    return removeAttachment(ticketId, fileId);
  },

  convertVideo({ commit }, payload): void {
    convertVideo(payload.objectId, payload.fieldName);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
