import { plainToClass } from 'class-transformer';
import UserAuthObject from '../model/UserAuthObject';
import {
  doGetRequest,
  doPostRequest,
  doSecureDeleteRequest,
  doSecureGetRequest,
  doSecurePostRequest,
  doSecurePutRequest
} from './ApiService';
import User from '../model/User';
import Team from '../model/Team';
import UserRole from '../model/UserRole';
import UserSearchParams from '../types/UserSearchParams';
import InviteUsersParams from '../types/InviteUsersParams';
import { deleteCurrentAuth, isAuthenticated } from './SecurityService';

function login(email: string, password: string): Promise<UserAuthObject> {
  return doPostRequest<UserAuthObject>('/login', {
    email,
    password,
    agreedTC: true
  }).then((userAuth) => plainToClass(UserAuthObject, userAuth));
}

function register(email: string, language: string): Promise<boolean> {
  return doPostRequest<boolean>('/register', { email, language });
}

function confirmRegistration(token: string): Promise<UserAuthObject> {
  return doGetRequest<UserAuthObject>(`/register/confirm?token=${token}`)
    .then((userAuth) => plainToClass(UserAuthObject, userAuth));
}

function forgotPassword(email: string, language: string): Promise<boolean> {
  return doPostRequest<boolean>('/forgot-password', { email, language });
}

function confirmForgotPassword(password: string, token: string): Promise<UserAuthObject> {
  return doPostRequest<UserAuthObject>(`/forgot-password/confirm?token=${token}`, { password })
    .then((userAuth) => plainToClass(UserAuthObject, userAuth));
}

function setPassword(password: string): Promise<UserAuthObject> {
  return doSecurePostRequest<UserAuthObject>('/set-password', { password })
    .then((userAuth) => plainToClass(UserAuthObject, userAuth));
}

function fetchUsers(params: UserSearchParams): Promise<Array<User>> {
  const url = `/users?${params.buildUrl()}`;
  return doSecureGetRequest<Array<User>>(url)
    .then((user) => plainToClass(User, user));
}

function fetchUsersByBatch(targets: Array<string>): Promise<Array<User>> {
  return doSecurePostRequest<Array<User>>('/users/fetchByBatch', { targets })
    .then((user) => plainToClass(User, user));
}

function fetchUserRolesByBatch(targets: Array<string>): Promise<Array<UserRole>> {
  return doSecurePostRequest<Array<UserRole>>('/users/fetchRolesByBatch', { targets })
    .then((userRoles) => plainToClass(UserRole, userRoles));
}

function fetchUserById(userId: string): Promise<User> {
  return doSecureGetRequest<User>(`/user/${userId}`)
    .then((user) => plainToClass(User, user));
}

function updateUser(userId: string, payload: User): Promise<User> {
  return doSecurePutRequest<User>(`/user/${userId}`, payload)
    .then((updatedUser) => plainToClass(User, updatedUser));
}

function updateUserRoles(userId: string, roles: Array<string>): Promise<Array<string>> {
  const url = `/user/${userId}/updateRole`;
  return doSecurePostRequest<Array<string>>(url, { roles });
}

function deleteUser(userId: string): Promise<string> {
  return doSecureDeleteRequest<string>(`/user/${userId}`);
}

function fetchSubscribedTeams(userId: string): Promise<Array<Team>> {
  return doSecureGetRequest<Team>(`/team/${userId}/subscribedTeams`)
    .then((teams) => plainToClass(Team, teams) as any);
}

function acceptTermsAndConditions(): Promise<boolean> {
  return doSecurePostRequest<boolean>('/user/acceptTermsAndConditions');
}

function inviteUsersByInvitation(payload: InviteUsersParams): Promise<boolean> {
  return doSecurePostRequest<boolean>('/invite-users', payload);
}

async function logout(): Promise<void> {
  const logoutUrl = process.env.VUE_APP_LOGOUT_URL || '/logout';
  let ssoLogoutUrl;
  try {
    if (isAuthenticated()) {
      ssoLogoutUrl = await doSecureGetRequest<string>(`/logout?returnTo=${logoutUrl}`);
    }
  } finally {
    deleteCurrentAuth(ssoLogoutUrl);
  }
}

export default {
  login,
  logout,
  register,
  confirmRegistration,
  forgotPassword,
  confirmForgotPassword,
  setPassword,
  fetchUsers,
  fetchUsersByBatch,
  fetchUserRolesByBatch,
  fetchUserById,
  updateUser,
  updateUserRoles,
  deleteUser,
  fetchSubscribedTeams,
  acceptTermsAndConditions,
  inviteUsersByInvitation
};
