import VueRouter, { RawLocation } from 'vue-router';

const originalPush = VueRouter.prototype.push;
// Override the push function to prevent the NavigationDuplicated error
VueRouter.prototype.push = async function push(location: RawLocation): Promise<any> {
  let result;
  try {
    result = await originalPush.call(this, location);
  } catch (error) {
    if (!['NavigationDuplicated', 'NavigationRedirectedError'].includes(error.name)) {
      console.error(error);
    }
  }
  return result;
};

export default VueRouter;
