import Vue from 'vue';
import { Store } from 'vuex';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import VueRouter from './VueRouteOverride';
import processRoute from './guard';
import featureCheck from './featureGuard';

Vue.use(VueRouter);
const router = (
  routes: Array<RouteConfig>,
  store: Store<any> /* Vuex.Store */,
  isAdmin = false
): VueRouter => {
  const vueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/logout',
        name: 'Logout',
        meta: {
          publicAccess: true
        },
        async beforeEnter(to, from, next) {
          await store.dispatch('logout');
          return next({ name: 'Login' });
        }
      },
      ...routes
    ],
    scrollBehavior(to, from): void {
      const hasQueryParams = !!Object.keys(to.query).length;
      const differentRoute = from.path !== to.path;

      if (differentRoute || !hasQueryParams) {
        if (!to.hash) {
          // instantly scroll to top without smooth transition
          window.scrollTo(0, 0);
          return;
        }

        const element = document.querySelector(to.hash) as HTMLElement;
        setTimeout(() => window.scrollTo({
          top: element?.offsetTop || 0,
          behavior: 'smooth'
        }), 100);
      }
    }
  });

  vueRouter.beforeEach(async (
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ) => processRoute(to, from, next, store, isAdmin));

  vueRouter.beforeEach(async (
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ) => featureCheck(to, from, next, store));

  return vueRouter;
};

export default router;
