export type UserRoleName = {
  name: string;
};

export default class UserRole {
  userId: string | null = null;

  creator: string | null = null;

  roles: Array<UserRoleName> = [];
}
