














import { Component } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'Footer'
})
export default class Footer extends Vue {
}
