export default class FileUploadRequest {
  acl?: string;

  file?: File;

  dataUrl?: string | null;

  key?: string;

  tags?: Map<string, string>;

  constructor(
    acl?: string,
    dataUrl?: string,
    key?: string,
    tags?: Map<string, string>,
    file?: File
  ) {
    this.acl = acl;
    this.dataUrl = dataUrl || null;
    this.file = file;
    this.key = key;
    this.tags = tags;
  }
}
