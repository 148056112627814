import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { UiState } from '../../types/UiState';
import { UploadState } from '../../types/UploadState';
import { OrganizationCustomization, ProductCustomization } from '../../types/ProductCustomization';

const state: UiState = {
  alertMessage: null,
  drawerIsOpen: true,
  showAlertMessage: false,
  uploadStates: [],
  uploadStatesByInputId: new Map<string, UploadState>(),
  organizationCustomization: {} as OrganizationCustomization
};

const updateUploadStateByInputId = (source: Array<UploadState>) => {
  state.uploadStatesByInputId = new Map(source.map((us: UploadState) => [us.inputId!, us]));
};

const getters: GetterTree<UiState, any> = {
  alertMessage: (st) => st.alertMessage,
  drawerIsOpen: (st) => st.drawerIsOpen,
  uploadStateByInputId: (st) => st.uploadStatesByInputId,
  uploadStates: (st) => st.uploadStates,
  showAlertMessage: (st) => st.showAlertMessage,
  organizationCustomization: (st) => st.organizationCustomization
};

const mutations: MutationTree<UiState> = {
  toggleDrawer(st: UiState, payload: boolean): void {
    st.drawerIsOpen = payload;
  },
  initUploadState(st: UiState, payload: UploadState): void {
    st.uploadStates = [...st.uploadStates, payload];
    updateUploadStateByInputId(st.uploadStates);
  },
  setUploadProgress(st: UiState, { inputId, uploadProgress }: UploadState): void {
    st.uploadStates = st.uploadStates.map((acc: UploadState) => {
      if (acc.inputId === inputId) {
        acc.uploadProgress = uploadProgress;
      }
      return acc;
    });
    updateUploadStateByInputId(st.uploadStates);
  },
  removeUploadState(st: UiState, inputId: string): void {
    st.uploadStates = st.uploadStates.filter((acc: UploadState) => acc.inputId !== inputId);
    updateUploadStateByInputId(st.uploadStates);
  },
  setOrganizationCustomization(st: UiState, { logo, productCustomization }): void {
    const customization = productCustomization
      ? productCustomization[process.env.VUE_APP_PLATFORM as keyof ProductCustomization] : null;

    st.organizationCustomization = {
      appName: customization?.name || process.env.VUE_APP_PLATFORM!,
      appLogo: customization?.logo || '/img/logo_dark.svg',
      logo: logo || '/img/logo-temp.png'
    };
  },
  setAlertMessage(st: UiState, payload): void {
    st.alertMessage = payload.message;
    st.showAlertMessage = payload.show;
    setTimeout(() => {
      st.alertMessage = null;
      st.showAlertMessage = false;
    }, 5000);
  }
};

const actions: ActionTree<UiState, any> = {
  toggleNavigationalDrawer({ commit }, payload): void {
    commit('toggleDrawer', payload);
  },
  setOrganizationCustomization({ commit }, payload): void {
    commit('setOrganizationCustomization', payload);
  },
  setAlertMessage({ commit }, payload): void {
    commit('setAlertMessage', payload);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
