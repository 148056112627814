export enum HistoryEventType {
  DETAILS_UPDATED = 'DETAILS_UPDATED',
  STATUS_UPDATED = 'STATUS_UPDATED'
}

export default class TicketHistory {
  createdDate: Date | undefined;

  creatorUser: string | undefined;

  eventType: HistoryEventType | undefined;

  field: string | undefined;

  newValue: string | undefined;

  previousValue: string | undefined;
}
