import { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import { Store } from 'vuex';
import { isAuthenticated } from '../services/SecurityService';

const authServer = process.env.VUE_APP_AUTHENTICATION_URL;

export default async function processRoute(
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  store: Store<any>, // Vuex.Store
  isAdmin: boolean
): Promise<void> {
  async function fetchCurrentUserIfNeeded() {
    // Fetch current user
    if (isAuthenticated() && !store.getters.currentUser) {
      await store.dispatch('fetchCurrentUser');
    }
  }

  async function loginByToken(toRoute: Route, nextRoute: NavigationGuardNext) {
    await store.dispatch('tokenLogin', toRoute.query['login-token']);
    const { query } = toRoute;
    delete query['login-token'];
    return nextRoute({ name: toRoute.name as string, params: toRoute.params, query });
  }

  // If login token is passed
  if (to.query['login-token']) {
    return loginByToken(to, next);
  }

  // If the route is public, let it pass
  if (to.matched.some((record: RouteRecord) => record.meta.publicAccess)) {
    return next();
  }

  // Redirect to Login if not authenticated
  if (!isAuthenticated() || (isAdmin && !store.getters.userIsAdmin())) {
    window.location.href = `${authServer}?redirect=${window.location.href}${isAdmin ? '&isAdmin=true' : ''}`;
  }

  await fetchCurrentUserIfNeeded();

  // Check LatestTCAcceptance only run on initial load
  if (!from.name
    && to.name !== 'Login'
    && store.getters.isAuthenticated
    && store.getters.currentUser?.needToAcceptLatestTC(store.getters.currentOrganization)) {
    return next({ name: 'Login' });
  }

  return next();
}
