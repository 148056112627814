enum FEATURE {
  KICKBOX_PORTAL = 'KICKBOX_PORTAL',
  KICKBOX_ADMIN = 'KICKBOX_ADMIN',
  IMPROVE_PORTAL = 'IMPROVE_PORTAL',
  IMPROVE_ADMIN = 'IMPROVE_ADMIN',
  IMPROVE_ADMIN_ASSESSMENT = 'IMPROVE_ADMIN_ASSESSMENT',
  // TODO: Temporary feature until there is a better divide
  IMPROVE_PORTAL_OTHER = 'IMPROVE_PORTAL_OTHER',
  IMPROVE_ADMIN_OTHER = 'IMPROVE_ADMIN_OTHER'
}

export default FEATURE;
