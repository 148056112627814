import UserMetaInfo from './UserMetaInfo';
import UserPreferences from './UserPreferences';
import CommonDto from './CommonDto';
import Organization from './Organization';
import RoleType from '../types/RoleType';
import AspectPrefixType from '../types/AspectPrefixType';

export default class User extends CommonDto {
  id: string | undefined;

  firstName: string | null = null;

  lastName: string | null = null;

  meta: UserMetaInfo;

  context: string | undefined;

  username: string | undefined;

  email: string | undefined;

  emailVerified: boolean | undefined;

  enabled: boolean | undefined;

  agreedTCVersion: number | undefined;

  avatar: string | null = null;

  language = 'EN';

  unit: string | undefined;

  roles: Array<string>;

  preferences: UserPreferences;

  constructor() {
    super();
    this.meta = new UserMetaInfo();
    this.preferences = new UserPreferences();
    this.roles = [];
  }

  static removePrefix(galeraId: string): string {
    return galeraId.replace(AspectPrefixType.USER, '');
  }

  static getUserIdsByRole(subs: Map<string, number>, level = 3200): Array<string> {
    const users: Array<string> = [];
    subs.forEach((subLevel: number, subKey: string) => {
      if (subLevel >= level) {
        users.push(User.removePrefix(subKey));
      }
    });
    return users;
  }

  isAdmin(): boolean {
    return !!this.roles.length && (
      this.roles.includes(RoleType.SUPER_ADMIN) || this.roles.includes(RoleType.ADMIN)
    );
  }

  needToAcceptLatestTC(organization: Organization): boolean {
    if (organization && !organization.termsAndConditions) {
      return false;
    }
    return this.agreedTCVersion !== organization?.termsAndConditions?.version;
  }
}
