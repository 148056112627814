import { plainToClass } from 'class-transformer';
import DynamicField from '../model/DynamicField';
import { doSecureGetRequest } from './ApiService';
import Workflow from '../model/Workflow';

function fetchDynamicField(type: string): Promise<DynamicField> {
  return doSecureGetRequest<Workflow>(`/dynamic-fields/${type}`)
    .then((w) => plainToClass(DynamicField, w));
}

function fetchDynamicData(url: string, searchFor = ''): Promise<any> {
  return doSecureGetRequest<any>(
    `${url}&searchText=${searchFor}&page=0&size=20&sort=ASC`,
    undefined,
    false
    // eslint-disable-next-line no-underscore-dangle
  ).then((data: any) => data._embedded.dynamicInformations);
}

export default {
  fetchDynamicField,
  fetchDynamicData
};
