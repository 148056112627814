import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Workflow from '../../model/Workflow';
import Transition from '../../model/Transition';
import WorkflowService from '../../services/WorkflowService';
import { WorkflowState } from '../../types/WorkflowState';
import ImproveTicketStatus from '../../types/ImproveTicketStatus';
import { TicketStatus } from '../../types/TicketStatus';

const defaultWorkflowName = process.env.VUE_APP_WORKFLOW_NAME || 'improve';

function resolveActiveStatuses(
  startStatus: string,
  transitions: Array<Transition>,
  stack: Array<string>
) {
  const startWorkflow = transitions
    .find((_) => _.transitionFrom === startStatus
      && _.transitionTo !== ImproveTicketStatus.ARCHIVED
      && _.transitionTo !== ImproveTicketStatus.SCRATCH);
  if (startWorkflow) {
    stack.push(startWorkflow.transitionFrom as string);
    resolveActiveStatuses(startWorkflow.transitionTo as string, transitions, stack);
  } else {
    stack.push(startStatus);
  }
}

const state: WorkflowState = {
  activeWorkflow: null,
  activeTransitions: null,
  activeWorkflowStatuses: null
};

const getters: GetterTree<WorkflowState, any> = {
  activeWorkflow: (st) => st.activeWorkflow,
  boardWorkflowStatuses: (st): TicketStatus[] => Object
    .entries(st.activeWorkflow?.settings?.status || {})
    .filter(([, { settings }]) => settings?.showOnAdminBoard)
    .map(([status]): TicketStatus => status as TicketStatus),
  activeTransitions: (st) => st.activeTransitions,
  activeWorkflowStatuses: (st) => st.activeWorkflowStatuses
};

const mutations: MutationTree<WorkflowState> = {
  setActiveWorkflow(st: WorkflowState, workflow: Workflow) {
    st.activeWorkflow = workflow;
    st.activeTransitions = st.activeWorkflow.transitions;
    const activeStatuses: Array<string> = [];
    resolveActiveStatuses('DRAFT', workflow.transitions, activeStatuses);
    st.activeWorkflowStatuses = activeStatuses;
  }
};

const actions: ActionTree<WorkflowState, any> = {
  async fetchWorkflow({
    commit,
    state: wrkState
  }, workflowName = defaultWorkflowName): Promise<Workflow> {
    if (wrkState.activeWorkflow) {
      return Promise.resolve(wrkState.activeWorkflow);
    }
    const workflow = await WorkflowService.fetchWorkflow(workflowName);
    commit('setActiveWorkflow', workflow);
    return Promise.resolve(workflow);
  },
  async fetchTransitions({
    dispatch,
    state: wrkState
  }, workflowName = defaultWorkflowName): Promise<Array<Transition>> {
    if (!wrkState.activeWorkflow) {
      await dispatch('fetchWorkflow', workflowName);
    }
    return Promise.resolve(wrkState.activeTransitions!);
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
