import NodeType from '../types/NodeType';
import TopicMetaData from './TopicMetaData';

export default class Topic {
  id: string | null;

  tagName: string;

  tagType: string;

  metaData: TopicMetaData;

  constructor(
    id: string | null,
    tagName: string,
    tagType: string | null
  ) {
    this.id = id;
    this.tagName = tagName;
    this.tagType = tagType || NodeType.TOPIC;
    this.metaData = new TopicMetaData();
  }

  getDelegateId(): string {
    return `${this.tagType}:${this.id}`;
  }
}

export enum TopicMemberType {
  TAG_OWNER = 'TAG_OWNER',
  TAG_OWNER_LEVEL = 3201,
  TAG_MEMBER = 'TAG_MEMBER',
  TAG_MEMBER_LEVEL = 3203,
  TAG_EXPERT = 'TAG_EXPERT',
  TAG_EXPERT_LEVEL = 3203
}
