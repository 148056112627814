import { plainToClass } from 'class-transformer';
import Ticket from '../model/Ticket';
import {
  doSecurePostRequest,
  doSecureGetRequest,
  doSecureDeleteRequest,
  doSecurePutRequest
} from './ApiService';
import TicketSearchParams from '../types/TicketSearchParams';
import TicketStats from '../types/TicketStats';
import TicketStatsParams from '../types/TicketStatsParams';
import Task from '../model/Task';

const config = {
  portalUrl: process.env.VUE_APP_PORTAL_URL
};

function createTicket(newTicket: Ticket): Promise<Ticket> {
  return doSecurePostRequest<Ticket>('/ticket', newTicket)
    .then((t) => plainToClass(Ticket, t));
}

function getAllTickets(searchBy: TicketSearchParams): Promise<Array<Ticket>> {
  const url = `/ticket?${searchBy.buildUrl()}`;
  return doSecureGetRequest<Array<Ticket>>(url)
    .then((tickets) => plainToClass(Ticket, tickets));
}

function getAssignedTickets(searchBy: TicketSearchParams): Promise<Array<Ticket>> {
  const url = `/ticket/fetchAssignedTickets?${searchBy.buildUrl()}`;
  return doSecureGetRequest<Array<Ticket>>(url)
    .then((tickets) => plainToClass(Ticket, tickets));
}

function updateTicket(
  ticketUpdates: Ticket
): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(`/ticket/${ticketUpdates.canonicalId}`, ticketUpdates)
    .then((ticket) => plainToClass(Ticket, ticket));
}

function updateTasks(
  parentTicket: Ticket,
  tasks: Array<Task>
): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(`/ticket/${parentTicket.canonicalId}/tasks`, { tasks })
    .then((ticket) => plainToClass(Ticket, ticket));
}

function updateTicketStatus(canonicalId: string, status: string): Promise<Ticket> {
  return doSecurePutRequest<Ticket>(`/ticket/${canonicalId}/status`, { status })
    .then((ticket) => plainToClass(Ticket, ticket));
}

function getTicketById(canonicalId: string): Promise<Ticket> {
  return doSecureGetRequest<Ticket>(`/ticket/${canonicalId}`)
    .then((ticket) => plainToClass(Ticket, ticket));
}

async function getTicketStats(
  statsParams: TicketStatsParams = new TicketStatsParams()
): Promise<TicketStats> {
  const url = `/stats/fetchTicketStats?${statsParams.buildUrl()}`;
  const stats = await doSecureGetRequest<any>(url);
  const byStatus = new Map(Object.keys(stats.byStatus)
    .map((s: string) => [s, parseInt(stats.byStatus[s], 10)]));
  return new TicketStats(byStatus, parseInt(stats.total, 10));
}

function removeTicketById(canonicalId: string): Promise<void> {
  return doSecureDeleteRequest<void>(`/ticket/${canonicalId}`);
}

function openEditTicketInPortal(canonicalId: string): void {
  window.open(config.portalUrl!.concat('/edit-ticket/').concat(canonicalId!));
}

function openTicketInPortal(canonicalId: string): void {
  window.open(config.portalUrl!.concat('/ticket/').concat(canonicalId!));
}

export default {
  createTicket,
  getAllTickets,
  getAssignedTickets,
  getTicketById,
  updateTicket,
  updateTasks,
  removeTicketById,
  updateTicketStatus,
  getTicketStats,
  openEditTicketInPortal,
  openTicketInPortal
};
