import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import security from './modules/security';
import workflow from './modules/workflow';
import organization from './modules/organization';
import topic from './modules/topic';
import ticket from './modules/ticket';
import quetal from './modules/quetal';
import team from './modules/team';
import ui from './modules/ui';
import feature from './modules/feature';
import search from './modules/search';
import dynamicField from './modules/dynamicField';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    security,
    ticket,
    organization,
    feature,
    topic,
    workflow,
    quetal,
    team,
    ui,
    search,
    dynamicField
  }
});
