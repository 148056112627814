import { Type } from 'class-transformer';
import Topic from './Topic';
import CommonDto from './CommonDto';
import TicketMetaInfo from '../types/TicketMetaInfo';
import TicketHistory, { HistoryEventType } from './TicketHistory';
import TicketOrganization from './TicketOrganization';
import TicketDelegationType from '../types/TicketDelegationType';
import VideoPitches from '../types/VideoPitches';
import { FileModel } from '../types/FileModel';

const config = {
  ticketType: process.env.VUE_APP_TICKET_TYPE,
};

export default class Ticket extends CommonDto {
  id: string | undefined;

  canonicalId: string | undefined;

  parent: string | undefined;

  context: string | undefined;

  title: string | undefined;

  name: string | undefined;

  assignedAt: Date | undefined;

  deadLine: Date | undefined;

  assignee: string | undefined;

  files: Array<FileModel> = [];

  creator: string | undefined;

  creatorTeam: string | undefined;

  delegate: string | null = null;

  history: Array<TicketHistory> = [];

  meta: TicketMetaInfo;

  organization: TicketOrganization | undefined;

  status: string | undefined;

  @Type(() => Ticket)
  tasks: Array<Ticket> = []

  type: string | undefined;

  constructor() {
    super();
    this.meta = new TicketMetaInfo();
    this.type = config.ticketType;
  }

  get cover(): FileModel | undefined {
    // Get the last item from the array to prevent problem with faulty uploads
    return this.getFiles('project-cover').slice(-1)[0];
  }

  get coverUrl(): string {
    return this.cover?.url || '';
  }

  getPitch(pitchType: VideoPitches): FileModel | undefined {
    return this.getFiles(pitchType)[0];
  }

  getProblem(): string | undefined {
    return this.meta?.problem;
  }

  getSolution(): string | undefined {
    return this.meta?.solution;
  }

  getTags(): Topic[] {
    const { tags } = this.meta;
    return tags ? JSON.parse(tags) : [];
  }

  getLinks(): Array<string> {
    if (this.meta && this.meta.link) {
      return this.meta.link.split('||');
    }
    return [];
  }

  setLinks(links: Array<string>): string | undefined {
    const link = links.length ? links.join('||') : undefined;
    if (this.meta) {
      this.meta.link = link;
    }
    return link;
  }

  delegateIsTeam(): boolean {
    if (this.delegate) {
      return this.delegate?.indexOf(TicketDelegationType.TEAM) >= 0;
    }
    return false;
  }

  delegateIsTopic(): boolean {
    if (this.delegate) {
      return this.delegate?.indexOf(TicketDelegationType.TOPIC) >= 0;
    }
    return false;
  }

  getDelegateID(): string | null {
    if (this.delegate) {
      const ind = this.delegate.indexOf(':');
      return this.delegate?.substr(ind + 1, this.delegate.length);
    }
    return null;
  }

  getDelegateType(): string | null {
    if (this.delegate) {
      return this.delegate?.substr(0, this.delegate.indexOf(':'));
    }
    return null;
  }

  getLastValidStatus(ignoreStatuses: Array<string> = []): string | undefined {
    const statuses = this.history
      .sort((x, y) => ((x.createdDate! > y.createdDate!) ? 1 : -1))
      .filter((hist) => hist.eventType === HistoryEventType.STATUS_UPDATED
        && !ignoreStatuses.includes(hist.newValue!))
      .map((hist) => hist.newValue);
    return statuses.length ? statuses.pop() : this.status;
  }

  private getFiles(category: string): FileModel[] {
    return this.files.filter((file: FileModel) => file.category === category);
  }
}
