const locales = {
  en: {
    code: 'en',
    messages: {
      alpha: '{_field_} may only contain alphabetic characters',
      alpha_num: '{_field_} may only contain alpha-numeric characters',
      alpha_dash: '{_field_} may contain alpha-numeric characters as well as dashes and underscores',
      alpha_spaces: '{_field_} may only contain alphabetic characters as well as spaces',
      between: '{_field_} must be between {min} and {max}',
      confirmed: '{_field_} confirmation does not match',
      digits: '{_field_} must be numeric and exactly contain {length} digits',
      dimensions: '{_field_} must be {width} pixels by {height} pixels',
      email: '{_field_} must be a valid email',
      excluded: '{_field_} is not a valid value',
      ext: '{_field_} is not a valid file',
      image: '{_field_} must be an image',
      integer: '{_field_} must be an integer',
      length: '{_field_} must be {length} long',
      max_value: '{_field_} must be {max} or less',
      max: '{_field_} may not be greater than {length} characters',
      mimes: '{_field_} must have a valid file type',
      min_value: '{_field_} must be {min} or more',
      min: '{_field_} must be at least {length} characters',
      numeric: '{_field_} may only contain numeric characters',
      oneOf: '{_field_} is not a valid value',
      regex: '{_field_} format is invalid',
      required_if: '{_field_} is required',
      required: '{_field_} is required',
      size: '{_field_} size must be less than {size}KB',
      double: '{_field_} must be a valid decimal'
    }
  },
  de: {
    code: 'de',
    messages: {
      alpha: '{_field_} darf nur alphabetische Zeichen enthalten',
      alpha_num: '{_field_} darf nur alphanumerische Zeichen enthalten',
      alpha_dash: 'Das {_field_} kann alphanumerische Zeichen sowie Binde- und Unterstriche enthalten contain',
      alpha_spaces: '{_field_} darf nur alphabetische Zeichen sowie Leerzeichen enthalten',
      between: '{_field_} muss zwischen {min} und {max}',
      confirmed: '{_field_} Bestätigung stimmt nicht überein',
      digits: 'Das {_field_} muss numerisch sein und genau {length} Ziffern enthalten',
      dimensions: 'Das {_field_} muss {width} Pixel mal {height} Pixel groß sein',
      email: 'Das {_field_} muss eine gültige E-Mail-Adresse sein',
      excluded: '{_field_} ist kein gültiger Wert',
      ext: '{_field_} ist keine gültige Datei',
      image: '{_field_} muss ein Bild sein',
      integer: '{_field_} muss eine ganze Zahl sein',
      length: 'Das {_field_} muss {length} lang sein',
      max_value: '{_field_} darf höchstens {max} sein',
      max: 'Das {_field_} darf nicht länger als {length} Zeichen sein',
      mimes: 'Das {_field_} muss einen gültigen Dateityp haben',
      min_value: 'Das {_field_} muss mindestens {min} sein',
      min: 'Das {_field_} muss mindestens {length} Zeichen lang sein',
      numeric: '{_field_} darf nur numerische Zeichen enthalten',
      oneOf: '{_field_} ist kein gültiger Wert',
      regex: '{_field_} format ist ungültig',
      required_if: '{_field_} ist erforderlich',
      required: '{_field_} ist erforderlich',
      size: '{_field_} Größe muss kleiner sein als {size}KB',
      double: '{_field_} muss eine gültige Dezimalzahl sein'
    }
  }
};

export default locales;
