import Team from './Team';
import CommonDto from './CommonDto';
import Content from '../types/Content';
import { ProductCustomization } from '../types/ProductCustomization';

export default class Organization extends CommonDto {
  id: string | undefined;

  shortCode: string | undefined;

  domains: Array<string> | undefined;

  name: string | undefined;

  enabled: boolean | undefined;

  context: string | undefined;

  defaultLanguage: string | undefined;

  logo: string | undefined;

  productCustomization: ProductCustomization | undefined;

  availableLanguages: Array<string> = [];

  teams: Array<Team> = [];

  termsAndConditions: Content | null = null;

  projectDeal: Content | null = null;

  getProjectDeal(language: string): string {
    return this.projectDeal?.content[language.toUpperCase()]
      || this.projectDeal?.content.EN
      || '';
  }

  getTermsAndConditionsLink(language: string): string | null {
    if (this.termsAndConditions && this.termsAndConditions.content[language.toUpperCase()]) {
      const value = this.termsAndConditions?.content[language.toUpperCase()];
      const linkValues = value ? value.split('||') : [];
      return linkValues.length ? linkValues[0] : null;
    }
    return null;
  }

  getPrivacyPolicyLink(language: string): string | null {
    if (this.termsAndConditions && this.termsAndConditions.content[language.toUpperCase()]) {
      const value = this.termsAndConditions?.content[language.toUpperCase()];
      const linkValues = value ? value.split('||') : [];
      return linkValues.length ? linkValues[1] : null;
    }
    return null;
  }
}
