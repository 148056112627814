// tslint:disable-file
import { authHeader, deleteCurrentAuth } from './SecurityService';

const defaultEventHeaders = {
  'app-id': process.env.VUE_APP_APP_ID!,
  platform: process.env.VUE_APP_PLATFORM!
};

const config = {
  apiUrl: process.env.VUE_APP_API_URL
};

async function handleResponse(response: Response) {
  const text: string = await response.text();
  // If response.status is 406 it means unacceptable jwt was provided
  if (response.status === 406) {
    return deleteCurrentAuth();
  }
  // If response.status is 500 it means something was wrong with the server
  if (response.status === 500) {
    return Promise.reject(new Error('error.systemFailure'));
  }
  if (!response.ok) {
    return Promise.reject(text);
  }

  try {
    const data = JSON.parse(text);
    return Promise.resolve(data);
  } catch (ex) {
    return Promise.resolve(text);
  }
}

export const doGetRequest = async <T>(
  url: string,
  headers?: Record<string, string>,
  relativeUrl = true
): Promise<T> => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...defaultEventHeaders,
      ...headers
    }
  };
  const realUrl = relativeUrl ? `${config.apiUrl}${url}` : url;
  const response = await fetch(realUrl, requestOptions);
  return handleResponse(response);
};

export const doSecureGetRequest = async <T>(
  url: string,
  headers?: Record<string, string>,
  relativeUrl = true
): Promise<T> => doGetRequest(url, {
  ...authHeader(),
  ...headers
}, relativeUrl);

export const doPostRequest = async <T>(
  url: string,
  payload?: any,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...defaultEventHeaders,
      ...headers
    },
    body: JSON.stringify(payload)
  };

  const response = await fetch(`${config.apiUrl}${url}`, requestOptions);
  return handleResponse(response);
};

export const doSecureFileUpload = async <T>(
  url: string,
  payload?: any): Promise<T> => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...defaultEventHeaders,
      ...authHeader()
    },
    body: payload
  };

  const response = await fetch(`${config.apiUrl}${url}`, requestOptions as any);
  return handleResponse(response);
};

export const doSecurePostRequest = <T>(
  url: string,
  payload?: any,
  headers?: Record<string, string>): Promise<T> => doPostRequest<T>(url, payload, {
    ...authHeader(),
    ...headers
  });

export const doSecurePutRequest = async <T>(
  url: string,
  payload?: any,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...defaultEventHeaders,
      ...authHeader(),
      ...headers
    },
    body: JSON.stringify(payload)
  };
  const response = await fetch(`${config.apiUrl}${url}`, requestOptions);
  return handleResponse(response);
};

export const doSecureDeleteRequest = async <T>(
  url: string,
  headers?: Record<string, string>
): Promise<T> => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...defaultEventHeaders,
      ...authHeader(),
      ...headers
    }
  };
  const response = await fetch(`${config.apiUrl}${url}`, requestOptions as any);
  return handleResponse(response);
};
