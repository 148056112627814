























import Vue from 'vue';
import 'reflect-metadata';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import styleVariables from '../../styles/export.scss';

@Component({
  name: 'Snackbar'
})
export default class Snackbar extends Vue {
  @Getter isAuthenticated!: boolean;

  @Getter showAlertMessage!: boolean;

  @Getter alertMessage!: string | null;

  @Action setAlertMessage!: ActionMethod;

  styleVariables = styleVariables;

  hideAlertMessage(): void {
    this.setAlertMessage({
      message: null,
      show: false
    });
  }
}
