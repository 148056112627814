import ReactionEvent from './ReactionEvent';

export default class Reaction {
  constructor(reactionKey: string, events: Array<ReactionEvent>) {
    this.reactionKey = reactionKey;
    this.events = events;
  }

  reactionKey: string;

  events: Array<ReactionEvent>;
}
