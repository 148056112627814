import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { classToClass } from 'class-transformer';
import dayjs from 'dayjs';
import { localize } from 'vee-validate';
import UserService from '../../services/UserService';
import analyticsService from '../../services/analyticsService';
import { uploadBase64File, uploadFile } from '../../services/FileUploadService';
import { getCurrentAuth, setCurrentAuth, userIsAdmin } from '../../services/SecurityService';
import { Base64Response, SingleFileResponse } from '../../types/FileUploadResponse';
import FileUploadRequest from '../../types/FileUploadRequest';
import { UserState } from '../../types/UserState';
import RoleType from '../../types/RoleType';
import UserAuthObject from '../../model/UserAuthObject';
import User from '../../model/User';
import Organization from '../../model/Organization';
import UserRole, { UserRoleName } from '../../model/UserRole';
import Team from '../../model/Team';
import TeamStore from './team';
import UserSearchParams from '../../types/UserSearchParams';
import InviteUsersParams from '../../types/InviteUsersParams';
import i18n from '../../i18n';

const state: UserState = {
  token: '',
  roles: [],
  isAuthenticated: false,
  context: '',
  currentUserId: null,
  currentUser: null,
  currentUserUnit: null,
  hasCompletedProfile: false,
  users: null,
  userById: null,
  userRolesById: null,
  userSubscribedTeams: []
};

const getters: GetterTree<UserState, any> = {
  isAuthenticated: (st) => st.isAuthenticated || !!getCurrentAuth(),

  roles: (st) => st.roles || getCurrentAuth()?.roles,
  token: (st) => st.token || getCurrentAuth()?.token,
  currentUserId: (st) => st.currentUserId || getCurrentAuth()?.id,
  currentUser: (st) => st.currentUser,
  currentUserUnit: (st) => st.currentUserUnit,
  hasCompletedProfile: (st, unusedGetters, rootState) => !!(st.currentUser
    && st.currentUser.firstName
    && st.currentUser.lastName
    && st.currentUser.unit
    && !st.currentUser.needToAcceptLatestTC(rootState.organization.currentOrganization!)),
  users: (st) => st.users,
  userById: (st) => (id: string) => st.userById?.get(id),
  userSubscribedTeams: (st) => st.userSubscribedTeams
};

const mutations: MutationTree<UserState> = {
  setAuth(st: UserState, payload: UserAuthObject) {
    st.token = payload.token;
    st.roles = payload.roles;
    st.context = payload.context;
    st.currentUserId = payload.id;
    st.isAuthenticated = true;
    setCurrentAuth(payload);
  },
  setCurrentUser(st: UserState, payload: User) {
    st.currentUser = payload;
    st.currentUser.roles = getCurrentAuth()?.roles || [];
    // Set current users Organizational Unit
    if (st.currentUser.unit) {
      st.currentUserUnit = TeamStore.state.organizationalUnitsByID.get(st.currentUser.unit)
        || null;
    }
  },
  setUserSubscribedTeams(st: UserState, payload: Array<Team>) {
    st.userSubscribedTeams = payload;
  },
  setCurrentUserAvatar(st: UserState, avatar: string | null) {
    if (st.currentUser) {
      st.currentUser.avatar = avatar;
    }
  },
  setUsers(st: UserState, users: Array<User>) {
    const newUsers = users.filter((u) => !st.userById?.has(u.id!));
    st.users = [...(st.users || []), ...newUsers];
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },
  setUsersByBatch(st: UserState, users: Array<User>) {
    if (!st.users || !st.users.length) {
      st.users = users;
    } else {
      users.forEach((u) => {
        if (!st.userById || !st.userById.has(u.id!)) {
          st.users?.push(u);
        }
      });
    }
    st.users = classToClass(st.users);
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },
  setUserRoles(st: UserState, userRoles: Array<UserRole>) {
    st.userRolesById = new Map(userRoles.map((r) => [
      r.userId!,
      r.roles.map((_: UserRoleName) => _.name)
    ]));

    // Inject the roles into users instances
    if (st.users && st.users.length) {
      st.users = st.users.map((user: User) => {
        const newUserInstance = classToClass(user);
        const roles = st.userRolesById?.get(user.id!);

        if (roles && roles.length) {
          newUserInstance.roles = roles;
        }
        st.userById?.set(user.id!, newUserInstance);
        return newUserInstance;
      });
    }
  },
  updateUserRoles(st: UserState, payload: { id: string; roles: Array<string> }) {
    if (!st.users || !st.users.length) {
      return;
    }
    st.users = st.users.map((u: User) => {
      if (u.id === payload.id) {
        const newUser = classToClass(u);
        newUser.roles = payload.roles;
        return newUser;
      }
      return u;
    });
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },
  removeUserById(st: UserState, userId: string) {
    if (!st.users || !st.users.length) {
      return;
    }
    st.users = st.users?.filter((u: User): boolean => u.id !== userId);
    st.userById = new Map(st.users.map((u: User) => [u.id!, u]));
  },

  acceptTermsAndConditions(st: UserState, org: Organization) {
    if (st.currentUser && org?.termsAndConditions) {
      const newCurrentUser = classToClass(st.currentUser);
      newCurrentUser.agreedTCVersion = org.termsAndConditions.version;
      st.currentUser = newCurrentUser;
    }
  }
};

const actions: ActionTree<UserState, any> = {

  async setAuthorization({ commit, dispatch }, auth: UserAuthObject) {
    commit('setAuth', auth);
    await dispatch('fetchCurrentUser');
  },

  async login({ dispatch }, { email, password, isAdmin = false }): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.login(email, password);
    if (isAdmin && !userIsAdmin(auth)) {
      throw new Error('No admin privileges');
    }
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async tokenLogin({ dispatch }, token: string, isAdmin = false): Promise<UserAuthObject> {
    const auth = UserAuthObject.createFromToken(token);
    if (isAdmin && !userIsAdmin(auth)) {
      throw new Error('No admin privileges');
    }
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async register(_context, payload): Promise<boolean> {
    return UserService.register(payload.email, payload.language);
  },

  async confirmRegistration({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.confirmRegistration(payload.token);
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async forgotPassword(_context, payload): Promise<boolean> {
    return UserService.forgotPassword(payload.email, payload.language);
  },

  async confirmForgotPassword({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService
      .confirmForgotPassword(payload.password, payload.token);
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async setPassword({ dispatch }, payload): Promise<UserAuthObject> {
    const auth: UserAuthObject = await UserService.setPassword(payload.password);
    await dispatch('setAuthorization', auth);
    return Promise.resolve(auth);
  },

  async fetchCurrentUser({ dispatch, commit, getters: uGttrs }): Promise<User | null> {
    const user = await UserService.fetchUserById(uGttrs.currentUserId);
    await dispatch('fetchCurrentOrganization', null, { root: true });
    await dispatch('fetchTopicsByContext', null, { root: true });
    await dispatch('fetchFeatures');
    await commit('setCurrentUser', user);
    dayjs.locale(user.language.toLowerCase());
    i18n.locale = user.language.toLowerCase();
    localize(i18n.locale);
    analyticsService.setUserData(user);
    analyticsService.initSmartlook(user);
    return Promise.resolve(user);
  },

  async fetchUsers(
    { commit, dispatch },
    payload: {
      params: UserSearchParams;
      fetchRoles: boolean;
    } = {
      params: new UserSearchParams(),
      fetchRoles: false
    }
  ): Promise<Array<User>> {
    let users = await UserService.fetchUsers(payload.params) || [];

    // Take only the enabled ones
    // Will fix it later in backend
    commit('setUsers', users.filter((u: User) => u.enabled));

    // Optional fetches based on Users
    if (payload?.fetchRoles) {
      const userRoles: UserRole[] = await dispatch('fetchUserRolesByBatch', users
        .map((_: User) => _.id));

      state.userRolesById = new Map(userRoles.map((r) => [
        r.userId!,
        r.roles.map((_: UserRoleName) => _.name)
      ]));

      users = users.map((user: User) => {
        const newUserInstance = classToClass(user);
        const roles = state.userRolesById?.get(user.id!);

        if (roles && roles.length) {
          newUserInstance.roles = roles;
        }
        state.userById?.set(user.id!, newUserInstance);
        return newUserInstance;
      });
    }
    return Promise.resolve(users);
  },

  async fetchUsersByBatch(
    { commit, getters: stateGetters, state: userState },
    targets: Array<string>
  ): Promise<Array<User>> {
    if (!targets.length) {
      return Promise.resolve([]);
    }
    const doesntExistTargets: string[] = [...new Set(
      Array.from(targets).filter((_: string) => !userState.userById || !userState.userById.has(_))
    )];
    if (doesntExistTargets.length) {
      const users = await UserService.fetchUsersByBatch(doesntExistTargets);
      commit('setUsersByBatch', users);
    }
    return Promise.resolve(targets.map((t) => stateGetters.userById(t)));
  },

  async fetchUserRolesByBatch({ commit }, targets: Array<string>): Promise<Array<UserRole>> {
    const uniqueTargets: string[] = [...new Set(targets)];
    const userRoles: UserRole[] = await UserService.fetchUserRolesByBatch(uniqueTargets);
    commit('setUserRoles', userRoles);
    return Promise.resolve(userRoles);
  },

  async fetchUserTeams({ commit }, userId): Promise<Array<Team>> {
    const teams = await UserService.fetchSubscribedTeams(userId);
    commit('setUserSubscribedTeams', teams);
    return Promise.resolve(teams);
  },

  async updateCurrentUser(_context, payload: User): Promise<User> {
    const user: User = await UserService.updateUser(_context.getters.currentUserId, payload);
    _context.commit('setCurrentUser', user);
    return Promise.resolve(user);
  },

  async addUserRoles(_context, payload: {
    user: User;
    roles: Array<RoleType>;
  }): Promise<Array<string>> {
    const roles: string[] = await UserService.updateUserRoles(payload.user.id!, payload.roles);
    _context.commit('updateUserRoles', {
      id: payload.user.id!,
      roles
    });
    return Promise.resolve(roles);
  },

  async removeUserRoles(_context, payload: {
    user: User;
    roles: Array<RoleType>;
  }): Promise<Array<string>> {
    // Get all the other roles
    const roles: string[] = payload.user.roles
      .filter((role: string) => !payload.roles.includes(role as RoleType));
    if (!roles.length) {
      roles.push(RoleType.USER);
    }

    const updatedRoles = await UserService.updateUserRoles(payload.user.id!, roles);
    _context.commit('updateUserRoles', {
      id: payload.user.id!,
      roles: updatedRoles
    });
    return Promise.resolve(updatedRoles);
  },

  async deleteUser(_context, userId: string): Promise<string> {
    const deletedUserId = await UserService.deleteUser(userId);
    _context.commit('removeUserById', deletedUserId);
    return Promise.resolve(deletedUserId);
  },

  async uploadUserAvatarBase64(_context, base64DataUrl): Promise<Base64Response> {
    const fileName = `${_context.getters.currentUserId}-${new Date().getTime()}-avatar`;
    const res: Base64Response = await uploadBase64File(new FileUploadRequest(
      'public-read',
      base64DataUrl,
      `images/${fileName}`
    ));
    this.commit('setCurrentUserAvatar', res.location);
    return Promise.resolve(res);
  },

  async uploadUserAvatarFile(_context, payload): Promise<SingleFileResponse> {
    const fileName = `${_context.getters.currentUserId}-avatar`;
    const res: SingleFileResponse = await uploadFile(fileName, payload);
    this.commit('setCurrentUserAvatar', res.payload?.location);
    return Promise.resolve(res);
  },

  async acceptTermsAndConditions({ commit, rootGetters }): Promise<boolean> {
    const response = await UserService.acceptTermsAndConditions();
    commit('acceptTermsAndConditions', rootGetters.currentOrganization);
    return Promise.resolve(response);
  },

  inviteUsersByEmail({ commit }, payload: InviteUsersParams): Promise<boolean> {
    return UserService.inviteUsersByInvitation(payload);
  },

  removeUserAvatar(): void {
    this.commit('setCurrentUserAvatar', undefined);
  },

  logout(): void {
    UserService.logout();
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
